/*
 * $Id: $
 *
 * Copyright (C) 2022 ITEG IT-Engineers GmbH
 */

// We use integers for hashing here for consistency with other programming languages.
// Unfortunatly javascript does not have 64 bit integer so 32 bits will have to be enough.

// eslint-disable-next-line
const identityMap: WeakMap<object, number> = new WeakMap();
// We assume that symbols live about as long as the application and are relatively small.
const symbolIdentityMap: Map<symbol, number> = new Map();
let identityHash = 7 | 0;

// eslint-disable-next-line
export function getIdentityHash(obj: object): number {
    let existing = identityMap.get(obj);
    if (existing == null) {
        existing = identityHash | 0;
        identityHash = ((identityHash | 0) + 1) | 0;
        identityMap.set(obj, existing);
    }
    return existing | 0;
}

export function getSymbolIdentityHash(sym: symbol): number {
    let existing = symbolIdentityMap.get(sym);
    if (existing == null) {
        existing = identityHash | 0;
        identityHash = ((identityHash | 0) + 1) | 0;
        symbolIdentityMap.set(sym, existing);
    }
    return existing | 0;
}

export function hash(value: unknown): unknown {
    if ((typeof value === "object" && value !== null) || typeof value === "function") {
        if ("hashCode" in value) {
            // @ts-ignore
            return value.hashCode();
        } else {
            return value;
        }
    } else {
        return value;
    }
}

function hashString(str: string): number {
    let hash = 4 | 0;
    for (let i = 0; i < str.length; i++) {
        hash = combineHashes(hash | 0, str.charCodeAt(i) | 0) | 0;
    }
    return hash;
}

export function numberHash(value: unknown): number {
    const t = typeof value;
    if ((t === "object" && value !== null) || typeof value === "function") {
        // @ts-ignore
        if ("hashCode" in value) {
            // @ts-ignore
            return value.hashCode() | 0;
        } else {
            // @ts-ignore
            return getIdentityHash(value) | 0;
        }
    }
    switch (t) {
        case "object":
            // null
            return 0 | 0;
        case "undefined":
            return 1 | 0;
        case "boolean":
            if (!value) {
                return 2 | 0;
            } else {
                return 3 | 0;
            }
        case "string":
            // @ts-ignore
            return hashString(value) | 0;
        case "number":
            // @ts-ignore
            return combineHashes(5 | 0, value | 0) | 0;
        case "bigint":
            // @ts-ignore
            return combineHashes(6 | 0, Number(value) | 0) | 0;
        case "symbol":
            // @ts-ignore
            return getSymbolIdentityHash(value) | 0;
        default:
            throw new Error("unreachable");
    }
}

export function combineHashes(existing: number, next: number): number {
    return (((31 * (existing | 0)) | 0) + (next | 0)) | 0;
}
