/* Copyright (C) 2021-2022 ITEG IT-Engineers GmbH
*/

import {AbortablePromise} from "./AbortablePromise";

// Though I would like it, exported enums should never be const
// https://ncjamieson.com/dont-export-const-enums/
// This is, because esbuild and others do not use the types and do not see them.
//
// noinspection JSUnusedGlobalSymbols
export enum RpcServerCodes {
    /** Default/Unknown Exception */
    ERROR_0 = -32000,
    INVOCATION_EXCEPTION = ERROR_0,

    /** SecurityException */
    ERROR_1 = -32001,
    SECURITY = ERROR_1,

    /** LoginRequiredException */
    ERROR_99 = -32099,
    LOGIN_REQUIRED = ERROR_99,

    METHOD_NOT_FOUND = -32601,

    /** Used both by Java and internally in this javascript class. */
    INTERNAL_ERROR = -32603,
}

/**
 * Default/Unknown Exception
 */
const JSON_RPC_SERVER_ERROR_0 = -32000;
/**
 * SecurityException
 */
const JSON_RPC_SERVER_ERROR_1 = -32001;
/**
 * LoginRequiredException
 */
const JSON_RPC_SERVER_ERROR_99 = -32099;

export const JSON_RPC_METHOD_NOT_FOUND = -32601;
/**
 * Used both by Java and internally in this javascript class.
 */
export const JSON_RPC_INTERNAL_ERROR = -32603;

export const JSON_RPC_INVOCATION_EXCEPTION = JSON_RPC_SERVER_ERROR_0;
export const JSON_RPC_SECURITY = JSON_RPC_SERVER_ERROR_1;
export const JSON_RPC_LOGIN_REQUIRED = JSON_RPC_SERVER_ERROR_99;

// exported only for JsonRpc2
export interface Response {
    id : number;
    result : unknown; // could be any object, or string
    jsonrpc : string;
    error : ResponseError;
}

interface ResponseErrorData {
    loginUrl? : string;
}

interface ResponseError {
    code : number;
    message : string;
    data : ResponseErrorData;
}

type RejectParam = ResponseError | {
    code : number;
    message? : string;
}

// Incomplete interface for smd, only containing the things we explicitly refer to here.
export interface Smd {
    services : Record<string, unknown>;
    target : string;
}

/**
 * Results in a Promise which contains the newly logged in user (id/name).
 */
export type ReloginHandler = (loginUrl : string) => Promise<string>;

/**
 * You can have some default revivers like for native Java/ES6 containers (Map/Set)
 */
export enum DefaultRevivers {
    NONE,
    JAVA_CONTAINER
}

/**A callback to log RPC server-side exceptions like LoginRequired or MethodNotExisting to the user.
 *
 * Note: I18n is not provided within the messages.
 *
 * Do not throw any exception.
 *
 * @param code *should* be within RpcServerCodes; however, this is server-provided data.
 * @param exceptionMessage The first line of the message which is probably the name of the exception
 * @param message The full message as sent by the server if JsonRpc-2.0 has been followed.
 */
export type LogCallback = (code : RpcServerCodes | number, exceptionMessage : string, message : string) => void;

export interface InitParams {
    /** The URL, the service is located at. url+"?smd" needs to be the SMD download.
     */
    url : string;

    /** If the URL returns an 401 (i.e. a login is needed), this callback is called.
     * In our framework, the exception is providing with an login url to contact.
     * The callback should provide the user with a dialog (or such) to login.
     */
    reloginHandler : ReloginHandler;
    // eslint-disable-next-line
    reviver? : DefaultRevivers | ((key : string, value : any) => any);

    /**
     * Is called with the code, the exception message (first line after the first colon) and the whole message.
     * Probably you want to log JSON_RPC_SECURITY as an error and JSON_RPC_LOGIN_REQUIRED as a warn/info only.
     */
    logCallback? : LogCallback;
    replacer? : DefaultRevivers | ((key: string, value: unknown) => unknown);
}

interface JsonRpcParams {
    smd : Smd;
    reloginHandler? : ReloginHandler;
    // eslint-disable-next-line
    reviver? : (key : string, value : any) => any;
    logCallback? : LogCallback;
    replacer? : (key: string, value: unknown) => unknown;
}

interface MapEntry {
    // eslint-disable-next-line
    k : any;
    // eslint-disable-next-line
    v : any;
}

/**
 * Using the static init() method, you can get a JSON RPC 2.0 service which is defined by its SMD.
 * JsonRPC acts as a proxy for these services, so that e.g. the user login is asked for.
 * Every method returns an AbortablePromise<>.
 */
export class JsonRpc {

    /** Initialises a Json RPC 2.0 service containing the methods returned by its SMD. (url+?smd)
     * The reviver can be a Default one, undefined, or your custom one.
     * @param params URL, reloginHandler, and (optionally) the reviver to use
     */
    public static async init<T>(
        params : InitParams,
    ) : Promise<T & JsonRpc> {

        let url : string = params.url;
        console.assert(url != null, "URL of service is missing");

        let reloginHandler = params.reloginHandler;

        let reviver = (params.reviver === DefaultRevivers.JAVA_CONTAINER)
            ? JsonRpc.containerReviver
            : ((params.reviver === DefaultRevivers.NONE)
                ? undefined : params.reviver);

        let replacer = (params.replacer === DefaultRevivers.JAVA_CONTAINER)
            ? JsonRpc.containerReplacer
            : ((params.replacer === DefaultRevivers.NONE)
                ? undefined : (params.replacer ?? params.reviver === DefaultRevivers.JAVA_CONTAINER ? JsonRpc.containerReplacer : undefined));

        let logCallback = params.logCallback;

        return fetch(url + "?smd")
            .then(response => response.json())
            .then(smd => {
                console.assert(smd.services != null, "SMD did not return a services array");
                return smd;
            })
            .then(smd => new JsonRpc({
                smd,
                reloginHandler,
                reviver,
                replacer,
                logCallback,
            }) as T & JsonRpc);
    }

    private readonly smd : Smd;
    private readonly reloginHandler : ReloginHandler;
    // eslint-disable-next-line
    private readonly reviver : (key : string, value : any) => any;

    private readonly logCallback : LogCallback;
    private readonly replacer : (key: string, value: unknown) => unknown;
    private lastId : number;

    private constructor(params : JsonRpcParams) {
        this.smd = params.smd;
        for (let methodName in this.smd.services) {
            this[methodName] = (...args) => this.callServiceMethod(methodName, args);
        }
        this.reloginHandler = params.reloginHandler;
        this.reviver = params.reviver;
        this.replacer = params.replacer;
        this.lastId = 0;
        this.logCallback = params.logCallback;
    }

    /** Special reviver for Java business logic stringifying Map<> and Set<> objects.
     *  No own object should contain "_t" as its property.
     */
    // eslint-disable-next-line
    public static containerReviver(_key : string, value : any) : any {
        if (value != null && typeof value == "object"
            && value._t != null && value._tp != null && value._v != null) {

            if (value._t == "java.util.Map") {
                // eslint-disable-next-line
                let map : Map<any, any> = new Map<any, any>();
                for (let pair of value._v) {
                    map.set(pair.k, pair.v);
                }
                return map;

            } else if (value._t == "java.util.Set") {
                // eslint-disable-next-line
                let derivedSet : Set<any> = new Set<any>();
                for (let key of value._v) {
                    derivedSet.add(key);
                }
                return derivedSet;
            } else if (value._t === "byte[]") {
                return Uint8Array.from(atob(value._v), s => s.charCodeAt(0));
            } else {
                console.warn("Will ignore unsupported type _t = [" + value._t + "], will return value unchanged.");
                return value;
            }
        } else {
            return value;
        }
    }

    /** Create an object structure for Set and Map the backend understands.
     * @param _key
     * @param value Any type. If Set/Map it modifies the answer
     */
    public static containerReplacer(_key : unknown, value : unknown) : unknown {
        if (value instanceof Set) {
            //@ts-ignore
            let keyArray : Array<unknown> = [];
            for (let k of value.keys()) {
                keyArray.push(k);
            }
            return {
                _t : "java.util.Set",
                _tp : undefined,
                _v : keyArray,
            };

        } else if (value instanceof Map) {
            let entries : MapEntry[] = [];
            for (let k of value.keys()) {
                entries.push({
                    k : k,
                    v : value.get(k),
                });
            }
            return {
                _t : "java.util.Map",
                _tp : undefined,
                _v : entries,
            };
        } else if (value instanceof Uint8Array) {
            return {
                _t: "byte[]",
            _v: btoa(value.reduce((acc, value) => acc + String.fromCharCode(value), "")),
            };
        } else {
            return value;
        }
    }

    // Deprecated, use init({}) instead
    public static async initDeferred<T>(
        url : string,
        reloginHandler : ReloginHandler,
    ) : Promise<T & JsonRpc> {
        return JsonRpc.init({
            url : url,
            reloginHandler : reloginHandler,
            reviver : DefaultRevivers.NONE,
        });
    }

    // Deprecated, use init({}) instead
    public static async initReviverDeferred(
        params : InitParams,
    ) : Promise<JsonRpc> {
        return JsonRpc.init(params);
    }

    /**
     * While you can use init() directly, it is expected this method will be used on every other application
     */
    public static async initContainerDeferred(
        url : string,
        reloginHandler : ReloginHandler,
    ) : Promise<JsonRpc> {
        return JsonRpc.init({
            url : url,
            reloginHandler : reloginHandler,
            reviver : DefaultRevivers.JAVA_CONTAINER,
        });
    }

    /** Check the Response from the backend on login-required, exceptions, or parse the result.
     * This method is public only, so that JsonRpc2 can use it as well!
     * @param id The (client unique) ID of the request.
     * @param method The called method (for logging)
     * @param response The from fetch response.
     * @param resolve Called with the result.
     * @param reject Called with any error
     * @param allowRelogin Ask only once for the login with this parameter set.
     *        Otherwise reject the response as an exception.
     * @param logCallback Only so that it can be static for share for JsonRpc2
     * @param targetUrl The smd.target or url of the service (parameter, so that it can be static)
     * @return The login URL to re-login to or null.
     */
    public static checkResponse(
        id : number,
        method : string,
        response : Response,
        resolve : (result : unknown) => void,
        reject : (params : RejectParam) => void,
        allowRelogin : boolean,
        logCallback : LogCallback,
        targetUrl : string
    ) : string | null {

        // correct JSON RPC version
        if (response.jsonrpc != "2.0") {
            console.log("Method [", method, "] on [", targetUrl, "] returned response with wrong protocol [", response.jsonrpc, "].");
            reject({
                "code" : JSON_RPC_INTERNAL_ERROR,
                "message" : "Method [" + method + "] on [" + targetUrl + "] returned response with wrong protocol [" + response.jsonrpc + "].",
            });
            return null;
        }

        if (response.id != id) {
            console.log("Method [", method, "] on [", targetUrl, "] returned response with wrong ID [", response.id, "].");
            reject({
                "code" : JSON_RPC_INTERNAL_ERROR,
                "message" : "Method [" + method + "] on [" + targetUrl + "] returned response with wrong ID [" + response.id + "].",
            });
            return null;
        }

        // we got any result
        if (response.result !== undefined) {
            // null is seemingly allowed
            resolve(response.result);
            return null;
        }

        // resp.error is set
        if (response.error !== undefined && response.error !== null) {

            // do the log call back, so that it can write onto a log message pane or into console etc
            let code = response?.error?.code;
            /* The following is not used outside of the logCallback.
               which I have deactivated, so that we can push the code into main without too much thought
            let message = response?.error?.message;
            let firstLine = message?.split("\n")[0];
            let exceptionMessage = firstLine?.indexOf(":") < 0
                ? firstLine
                : firstLine?.substring(firstLine?.indexOf(":") + 1)?.trim();
            logCallback?.(code, exceptionMessage, message);*/

            // with error code 32099 and loginurl is set
            if (allowRelogin && code == JSON_RPC_SERVER_ERROR_99) {
                if (response.error.data != null && typeof (response.error.data.loginUrl) == 'string') {
                    return response.error.data.loginUrl;
                }
            }

            // using SMD this should never occur or it is a version missmatch
            if (code == JSON_RPC_METHOD_NOT_FOUND) {
                console.log("Method [", method, "] on [", targetUrl, "] was not found [", response.error, "].");
                reject(response.error);
                return null;
            }

            console.log("Method [", method, "] on [", targetUrl, "] returned error [", response.error, "].");
            reject(response.error);
            return null;
        }

        // neither result nor error was helpful
        console.log("Method [", method, "] on [", targetUrl, "] returned response without result and error.");
        reject({
            "code" : JSON_RPC_INTERNAL_ERROR,
            "message" : "Method [" + method + "] on [" + targetUrl + "] returned response without result and error.",
        });
        return null;
    }

    /** For every method defined by the SMD service, a call to this method is generated.
     * It is the intercepting proxy method.
     */
    // eslint-disable-next- line
    private callServiceMethod(method : string, params : ReadonlyArray<unknown>) : AbortablePromise<unknown> {
        return new AbortablePromise((resolve, reject, signal) => {
            const id = ++this.lastId;

            this.callRaw(id, signal, method, params)
                .then((resp) => {
                        const loginUrl = JsonRpc.checkResponse(id, method, resp, resolve, reject,
                            this.reloginHandler != null, this.logCallback, this.smd.target);

                        if (loginUrl) {

                            console.log("Trying to login with URL [", loginUrl, "] invoking method [", method, "] on [", this.smd.target, "]");

                            this.reloginHandler(loginUrl).then(
                                (user) => {

                                    console.log("Re-invoking method [", method, "] on [",
                                        this.smd.target, "] after successfully login of [", user, "].");

                                    const id = ++this.lastId;

                                    this.callRaw(id, signal, method, params).then(
                                        (resp) => {
                                            JsonRpc.checkResponse(id, method, resp, resolve, reject,
                                                false, this.logCallback, this.smd.target);
                                        },
                                        (err) => {
                                            console.log("Transport error re-invoking method [", method, "] on [",
                                                this.smd.target, "]:", err);
                                            reject(err);
                                        },
                                    );
                                }, (err) => {
                                    console.log("Error during login invoking method [", method, "] on [", this.smd.target, "]:", err);
                                    reject(err);
                                },
                            );
                        }
                    },
                    (err) => {
                        console.log("Transport error invoking method [", method, "] on [", this.smd.target, "]:", err);
                        reject(err);
                    },
                );
        });
    }

    /** Create the POST request, fetch the response, and parse as json.
     */
    // eslint-disable-next-line
    private callRaw(id : number, signal : AbortSignal, method : string, params : ReadonlyArray<unknown>) {
        return fetch(this.smd.target, {
            signal,
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json',
            },
            body : JSON.stringify({
                "id" : id,
                "jsonrpc" : "2.0",
                "method" : method,
                "params" : params,
            }, this.replacer),

        }).then(response => {
            return response.text().then(text => {
                return JSON.parse(text, this.reviver);
            });
        });
    }
}
