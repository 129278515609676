/*
 * idea and most code from
 *  https://zzdjk6.medium.com/a-simple-implementation-of-abortable-promise-using-abortcontroller-with-typescript-2c163ee050e8
*/
/** If the promise was aborted, an instance of AbortError can be catch()ed off the Promise.
 */
export class AbortError extends Error {
    constructor(message : string = 'User aborted') {
        super(message);
        this.name = 'AbortError';
    }
}

/** The promise has abort() and an reason.
 */
export interface Abortable {
    abort(reason? : string) : void;
    wasAborted() : boolean;

    readonly abortReason? : string;
}

// The executor function should be passed to the constructor when create a Promise
// The signature of executor function comes from lib.es2015.promise.d.ts
interface ExecutorFunction<T> {
    (resolve : (value? : PromiseLike<T> | T) => void, reject : (reason? : any) => void) : void;
}

// The executor function should be passed to the constructor when create a AbortablePromise
export interface AbortableExecutorFunction<T> {
    (resolve : (value? : PromiseLike<T> | T) => void, reject : (reason? : any) => void, abortSignal : AbortSignal) : void;
}

/**You can use an AbortablePromise like a Promise, but you can call abort() on it as well.
 * This may leed to an aborted fetch() call. An AbortError is catchable, if abort() was called.
 * Note that an async method can only return a standard Promise class, so that calling await needs a construct like
 * Note that there is an ignoreAborts() method to catch any AbortError.
 *
 * try {
 *   this.running = this.doSomethingAbortable();
 *   let y = await this.running;
 *   this.running = null;
 * } catch (err) {
 *     this.running = null;
 *     if (err instanceof AbortError) {
 *         // user abortion may be fine
 *         return;
 *     }
 *     console.log("Error: " + err)
 * }
 * ...
 * if (this.running) this.running.abort();
 */
export class AbortablePromise<T> extends Promise<T> implements Abortable {

    /** One can abort() this promise. e.g. the underlying fetch() may get a signal
     * or some computation is aborted at the next possible step.
     * Method definition `.abort()`
     */
    public abort : Abortable['abort'];

    public wasAborted : Abortable['wasAborted'];

    /**
     * If the exception is an AbortError (i.e. abort() was called), it is ignored.
     * Important: .finally() are not called either!
     */
    public async ignoreAborts() : Promise<T> {
        return new Promise<T>((resolve, reject) => this
            .then(r => resolve(r))
            .catch((err) => {
                if (err instanceof AbortError) {
                    return; // ignore any AbortError
                }
                reject(err);
            }));
    }

    /** Getter to access abort reason
     */
    public get abortReason() : string | undefined {
        return this._abortReason;
    }

    private _abortReason? : string;

    /**
     * Constructor.
     * note we can provide 3 args: resolve, reject, abortSignal
     * @param executor Special executor having an abortSignal.
     */
    constructor(executor : AbortableExecutorFunction<T>) {
        const abortController = new AbortController();
        const abortSignal = abortController.signal;

        // This is the executor function to be passsed to the superclass - Promise
        const normalExecutor : ExecutorFunction<T> = (resolve, reject) => {
            abortSignal.addEventListener('abort', () => {
                reject(new AbortError(this.abortReason));
            });

            executor(resolve, reject, abortSignal);
        };

        super(normalExecutor);

        // Bind the abort method
        this.abort = reason => {
            this._abortReason = reason ? reason : 'Aborted';
            abortController.abort();
        };

        // The signal knows best whether it was aborted
        this.wasAborted = () => abortController.signal.aborted;
    }

    // Wrap other Promise instances to AbortablePromise
    static from = <T>(promise : Promise<T>) : AbortablePromise<T> => {
        // If promise is already an AbortablePromise, return it directly
        if (promise instanceof AbortablePromise) {
            return <AbortablePromise<T>>promise;
        }

        return new AbortablePromise<T>((resolve, reject) => {
            promise.then(resolve).catch(reject);
        });
    };
}
