/* Copyright (C) 2021 ITEG IT-Engineers GmbH */

export class AppContext {
    protected reloginHandler : (loginUrl : string) => Promise<string>;

    public setReloginHandler(reloginHandler : (loginUrl : string) => Promise<string>) : void {
        this.reloginHandler = reloginHandler;
    }

    public emitQuery(url : string, options : RequestInit) : Promise<Response> {
        if (this.reloginHandler == null) {
            throw new Error("Please provide a reloginHandler before using this function.");
        }

        return new Promise<Response>( (resolve,reject) => {
            fetch(url, options).then(
                (resp : Response) => {
                    if (resp.status == 401 && resp.headers.get("x-http-util-login-url") != null) {
                        const loginUrl : string = resp.headers.get("x-http-util-login-url");
                        console.log("Trying to login with URL [",loginUrl,"] fetching URL [", url, "].");
                        
                        this.reloginHandler(loginUrl).then(
                            (user : string) => {
                                console.log("Re-fetching URL [",url,"] after successfuly login of [", user, "].");
                                fetch(url, options).then(resolve,reject);
                            }, (err) => {
                                console.log("Error during login fetching URL [",url,"]:", err);
                                reject(err);
                            }
                        );
                    }
                    else {
                        resolve(resp);
                    }
                }, (err) => {
                    console.log("Fetchinfg URL [",url,"] failed:",err);
                    reject(err);
                }
            );
        });
    }
}
