export class DtoHelper {

    public static areMembersEqual(memberOne : any, memberTwo : any, codeType : string) : boolean {
        if (memberOne === memberTwo) {
            return true;
        }
        if (memberOne == null || memberTwo == null) {
            return false;
        }
        if (codeType.endsWith("[]")) {
            if (memberOne.length != memberTwo.length) {
                return false;
            } else {
                let arrayInstanceType : string = codeType.substring(0, codeType.length - 2);
                let result : boolean = true;
                for (let n = 0; n < memberOne.length; n++) {
                    result = result && DtoHelper.areMembersEqual(memberOne[n], memberTwo[n], arrayInstanceType);
                }
                return result;
            }
        } else if (["String", "Long", "Integer", "Boolean", "byte"].includes(codeType)) {
	    return memberOne === memberTwo;
        } else if (codeType == "UtcSeconds" || codeType == "Float" || codeType == "Double") {
            return Math.abs(memberOne - memberTwo) < 1e-15;
        } else {
            throw new Error("Unsupported case of areMembersEqual: memberOne [" + memberOne
                + "], memberTwo [" + memberTwo + "], codeType [" + codeType + "]");            
        }
    }
}
