/*
 * $Id: $
 *
 * Copyright (C) 2022 ITEG IT-Engineers GmbH
 */

import HashTable, { makeIterableIterator, StaticImplements } from "./HashTable";

// A slightly enhanced version of the standard Map that can store complex keys.
// Hashing will be done one of three ways:
// 1. If contentHash is given it will be used for hashing.
// 2. If the value is an object and has a hashCode method that method will be used.
// 3. Otherwise the value will be directly used as a hash.
// Equality is similar:
// 1. If contentEquals is given it will be used for equality.
// 2. If the value is an object and has an equals method that method will be used.
// 3. Otherwise strict comparison will be used.
export class HashMap<K, V> extends HashTable<K, [K, V]>  implements StaticImplements<MapConstructor, typeof HashMap> {
    static get [Symbol.species](): MapConstructor {
        return this;
    }

    public [Symbol.toStringTag]: string;

    constructor();
    constructor(it: Iterable<[K, V]>);
    constructor(contentEquals: (a: K, b: K) => boolean, contentHash: (value: K) => unknown);
    constructor(it: Iterable<[K, V]>, contentEquals: (a: K, b: K) => boolean, contentHash: (value: K) => unknown);

    constructor(a?: unknown, b?: unknown, c?: unknown) {
        if (arguments.length === 0) {
            super(undefined, undefined);
            return;
        } else if (arguments.length === 1) {
            super(undefined, undefined);
            for (const [k, v] of a as Iterable<[K, V]>) {
                this.set(k, v);
            }
        } else if (arguments.length === 2) {
            super(a as (a: K, b: K) => boolean, b as (value: K) => unknown);
        } else if (arguments.length === 3) {
            super(b as (a: K, b: K) => boolean, c as (value: K) => unknown);
            for (const [k, v] of a as Iterable<[K, V]>) {
                this.set(k, v);
            }
        }
    }

    protected _getKey(entry: [K, V]): K {
        return entry[0];
    }

    public get(key: K): V | undefined {
        return this._get(key)?.[1];
    }

    public set(key: K, value: V): this {
        return this._set([key, value]);
    }

    public delete(key: K): boolean {
        return this._delete(key) !== undefined;
    }

    public [Symbol.iterator](): IterableIterator<[K, V]> {
        return makeIterableIterator(() => this._entries());
    }

    public entries(): IterableIterator<[K, V]> {
        return makeIterableIterator(() => this._entries());
    }

    public keys(): IterableIterator<K> {
        return makeIterableIterator(function*(this: HashMap<K, V>) {
            for (const entry of this._entries()) {
                yield entry[0];
            }
        }.bind(this));
    }

    public values(): IterableIterator<V> {
        return makeIterableIterator(function*(this: HashMap<K, V>) {
            for (const entry of this._entries()) {
                yield entry[1];
            }
        }.bind(this));
    }

    public forEach(callback: (value: V, key: K, map: this) => void, thisArg?: unknown): void {
        for (const [k, v] of this._entries()) {
            if (arguments.length > 1) {
                callback.call(thisArg, v, k, this);
            } else {
                callback(v, k, this);
            }
        }
    }
}

Object.defineProperty(HashMap.prototype, Symbol.toStringTag, {
    configurable: true,
    value: "HashMap",
});

export default HashMap;
