export default {
    "januaryLong" : "January",
    "februaryLong" : "February",
    "marchLong" : "March",
    "aprilLong" : "April",
    "mayLong" : "May",
    "juneLong" : "June",
    "julyLong" : "July",
    "augustLong" : "August",
    "septemberLong" : "September",
    "octoberLong" : "October",
    "novemberLong" : "November",
    "decemberLong" : "December",

    "januaryShort" : "Jan",
    "februaryShort" : "Feb",
    "marchShort" : "Mar",
    "aprilShort" : "Apr",
    "mayShort" : "May",
    "juneShort" : "Jun",
    "julyShort" : "Jul",
    "augustShort" : "Aug",
    "septemberShort" : "Sep",
    "octoberShort" : "Oct",
    "novemberShort" : "Nov",
    "decemberShort" : "Dec",

    "mondayLong" : "Monday",
    "tuesdayLong" : "Tuesday",
    "wednesdayLong" : "Wednesday",
    "thursdayLong" : "Thursday",
    "fridayLong" : "Friday",
    "saturdayLong" : "Saturday",
    "sundayLong" : "Sunday"
};
