/*
 * $Id: $
 *
 * Copyright (C) 2021 ITEG IT-Engineers GmbH
 */
// WARNING: This file is transpiled to dojo-clazzes so if you change this file you should regenerate
// the file in dojo-clazzes. You should use a command similar to this:
// $ npx tsc --module AMD --lib "es5,dom" --target "es5" --outDir ~/eclipse-workspace/dojo-clazzes/src/main/resources/OSGI-INF/webapp/dojo/clazzes/dateTime ./clazzes-ts/dateTime/CldrPatternParser.ts
// this specific command assumes you are in the src/main/webapp subdirectory of clazzes-ts
// and the dojo-clazzes project is at ~/eclipse-workspace/dojo-clazzes.
// You should adapt these paths to your particular setup.

export enum TokenTag {
	LiteralString,
	SymbolToken
}

export class SymbolToken
{
	public readonly id: TokenTag.SymbolToken = TokenTag.SymbolToken;
	public readonly length: number;
	public readonly character: string;

	public constructor(length: number, character: string)
	{
		this.length = length;
		this.character = character;
	}
}

export class LiteralString
{
	public readonly id: TokenTag.LiteralString = TokenTag.LiteralString;
	public readonly content: string;

	public constructor(content: string)
	{
		this.content = content;
	}
}

export type Token = SymbolToken|LiteralString;

const aCode = "a".charCodeAt(0);
const zCode = "z".charCodeAt(0);
const ACode = "A".charCodeAt(0);
const ZCode = "Z".charCodeAt(0);

function isSymbol(character: string): boolean
{
	const code = character.charCodeAt(0);
	return (code <= zCode && code >= aCode) || (code <= ZCode && code >= ACode);
}

enum StateTag {
	SymbolState,
	Quote,
	LiteralChar,
	QuotedChar,
	EndQuote,
	End
}

abstract class StateBase
{
	public readonly tokens: Token[];

	public constructor(tokens: Token[])
	{
		this.tokens = tokens;
	}

	abstract next(character: string): State;
	abstract end(): void;
}

class SymbolState extends StateBase
{
	public readonly id: StateTag.SymbolState = StateTag.SymbolState;
	public readonly character: string;
	public readonly length: number;

	public constructor(tokens: Token[], character: string, length: number)
	{
		super(tokens);
		this.character = character;
		this.length = length;
	}

	private push(): void
	{
		this.tokens.push(new SymbolToken(this.length, this.character));
	}

	public next(character: string): State
	{
		if (isSymbol(character)) {
			if (this.character === character) {
				return new SymbolState(this.tokens, this.character, this.length + 1);
			} else {
				this.push();
				return new SymbolState(this.tokens, character, 1);
			}
		} else if (character === "'") {
			this.push();
			return new Quote(this.tokens, "");
		} else {
			this.push();
			return new LiteralChar(this.tokens, character);
		}
	}

	public end(): void {
		this.push();
	}
}

abstract class StringState extends StateBase
{
	public readonly content: string;

	public constructor(tokens: Token[], content: string)
	{
		super(tokens);
		this.content = content;
	}

	private push(): void {
		if (this.content.length > 0) {
			this.tokens.push(new LiteralString(this.content));
		}
	}

	public end(): void {
		this.push();
	}

	protected toSymbol(character: string): SymbolState {
		this.push();

		return new SymbolState(this.tokens, character, 1);
	}
}

class Quote extends StringState
{
	public readonly id: StateTag.Quote = StateTag.Quote;

	public next(character: string): State {
		if (character === "'") {
			return new LiteralChar(this.tokens, this.content + "'");
		} else {
			return new QuotedChar(this.tokens, this.content + character);
		}
	}

	public end(): void {
		throw new Error("Unbalanced quotes.");
	}
}

class LiteralChar extends StringState
{
	public readonly id: StateTag.LiteralChar = StateTag.LiteralChar;

	public next(character: string): State {
		if (character === "'") {
			return new Quote(this.tokens, this.content);
		} else if (isSymbol(character)) {
			return this.toSymbol(character);
		} else {
			return new LiteralChar(this.tokens, this.content + character);
		}
	}
}

class QuotedChar extends StringState
{
	public readonly id: StateTag.QuotedChar = StateTag.QuotedChar;

	public next(character: string): State {
		if (character === "'") {
			return new EndQuote(this.tokens, this.content);
		} else  {
			return new QuotedChar(this.tokens, this.content + character);
		}
	}

	public end(): void {
		throw new Error("unbalanced parentheses.");
	}
}

class EndQuote extends StringState
{
	public readonly id: StateTag.EndQuote = StateTag.EndQuote;

	public next(character: string): State {
		if (character === "'") {
			return new QuotedChar(this.tokens, this.content + "'");
		} else if (isSymbol(character)) {
			return this.toSymbol(character);
		} else {
			return new LiteralChar(this.tokens, this.content + character);
		}
	}
}

type State = SymbolState|Quote|LiteralChar|QuotedChar|EndQuote;

export function parse(pattern: string): Token[]
{
	let state: State = new LiteralChar([], "");
	for (let i = 0; i < pattern.length; i++) {
		state = state.next(pattern.charAt(i));
	}
	state.end();
	return state.tokens;
}

function isOnlyQuotes(s: string): boolean {
	for (let i = 0; i < s.length; i++) {
		if (s.charAt(i) !== "'") {
			return false;
		}
	}
	return true;
}

export function format(tokens: Token[]): string {
	let ret = "";
	for (const token of tokens) {
		if (token.id === TokenTag.LiteralString) {
			const content = token.content;
			const inner = content.split("'").join("''");

			ret += isOnlyQuotes(content) ? inner : ("'" + inner + "'");
		} else if (token.id === TokenTag.SymbolToken) {
			ret += token.character.repeat(token.length);
		}
	}
	return ret;
}
