enum LogLevel {
    ERROR = 0,
    WARN  = 1,
    INFO  = 2,
    DEBUG = 3
}

enum OutputFormat {
    SHORT   = "SHORT",
    DEFAULT = "DEFAULT"
}


function formatInt(input: number, digits: number): string {
    return input.toString().padStart(digits, '0');
}

export class TinyLog {
    private static outputFormat : OutputFormat = OutputFormat.DEFAULT;
    private static contextLogLevels : Map<string, LogLevel> = new Map();
    private static baseLogLevel : LogLevel = LogLevel.INFO;

    private static stringToLogLevel : Map<string, LogLevel> = new Map([
        ["ERROR", LogLevel.ERROR],
        ["WARN", LogLevel.WARN],
        ["INFO", LogLevel.INFO],
        ["DEBUG", LogLevel.DEBUG],
    ]);
    private static logLevelToString : Map<LogLevel, string> = new Map([
        [LogLevel.ERROR, "ERROR"],
        [LogLevel.WARN, "WARN"],
        [LogLevel.INFO, "INFO"],
        [LogLevel.DEBUG, "DEBUG"],
    ]);
    private static stringToOutputFormat : Map<string, OutputFormat> = new Map([
        ["SHORT", OutputFormat.SHORT],
        ["DEFAULT", OutputFormat.DEFAULT],
    ]);

    public static initialize(configFileUrl: string = "tinylog.json"): void {
        fetch(configFileUrl)
        .then(a => a.json())
        .then((data : unknown) => {
            if (data instanceof Array) {
                for (let i : number = 0; i < data.length; i++) {
                    if (typeof data[i].level === "string") {
                        let l : LogLevel = TinyLog.stringToLogLevel.get(data[i].level);
                        if (l !== undefined) {
                            if (typeof data[i].context === "string") {
                                TinyLog.contextLogLevels.set(data[i].context, l);
                            } else {
                                TinyLog.baseLogLevel = l;
                            }
                        }
                    }

                    if (typeof data[i].outputFormat == "string") {
                        TinyLog.outputFormat = TinyLog.stringToOutputFormat.get(data[i].outputFormat);
                    }
                }
                console && console.info(TinyLog.formatLogLine(LogLevel.INFO, "TinyLog", "Successfully parsed [tinylog.json]."));
            }
        }, function (err : unknown) {
            console && console.error(TinyLog.formatLogLine(LogLevel.ERROR, "TinyLog", "Error fetching [tinylog.json]: "), err);
        });
    }

    private static formatLogLine(level : LogLevel, ctxt : string, line : string) : string {
        let now : Date = new Date();
        let logLevelString : string = TinyLog.logLevelToString.get(level);

        // poor man's version of
        //  locale.format(new Date(),{datePattern:"yyyy-MM-dd",timePattern:"HH:mm:ss,SSS"})
        // in order to get rid of any date formatter API.
        if (TinyLog.outputFormat == OutputFormat.SHORT) {
            let shortCtxt : string = "";
            if (ctxt) {
                let tokens : string[] = ctxt.split(".");
                shortCtxt = tokens.length > 0 ? tokens[tokens.length - 1] : ctxt;
            }
			return `${formatInt(now.getHours(), 2)}:${formatInt(now.getMinutes(), 2)}:${formatInt(now.getSeconds(), 2)}.${formatInt(now.getMilliseconds(), 3)} ${logLevelString.substr(0, 1)} ${shortCtxt}: ${line}`;
        } else {
			return `${formatInt(now.getFullYear(), 4)}-${formatInt(now.getMonth() + 1, 2)}-${formatInt(now.getDate(), 2)} ${formatInt(now.getHours(), 2)}:${formatInt(now.getMinutes(), 2)}:${formatInt(now.getSeconds(), 2)}.${formatInt(now.getMilliseconds(), 3)} ${logLevelString.substr(0, 1)} ${ctxt}: ${line}`;
        }
    }

    // TODO-IE: Verify that we actually don't need this any longer.
    /*
    // Well the MSIE "arguments" array is a pseudo-array (not of type array, but
    // having the properties "0","1",.... and "length".
    // So, we have to join the args, because the console functions do not
    // take multiple arguments and they are not accessible via "apply".
    // Sad, sad MSIE world...
    private static msieLogMsg(args : any[]) : string {
	if (args.length == 1) {
	    return args[0];
	} else {
	    let ret : string = "";
	    for (var i=0;i<args.length;++i) {
		ret += args[i];
	    }
	    return ret;
	}
    }*/

    private ctxt : string;

    constructor(ctxt : string) {
        this.ctxt = ctxt;
    }

    private getLogLevel() : LogLevel {
        return TinyLog.contextLogLevels.get(this.ctxt) ?? TinyLog.baseLogLevel;
    }

    public isDebugEnabled() : boolean {
        return this.getLogLevel() >= LogLevel.DEBUG;
    }

    public debug(line: string, ...args : unknown[]) : void {
        if (this.isDebugEnabled() && console) {
            line = TinyLog.formatLogLine(LogLevel.DEBUG, this.ctxt, line);
            console.debug(line, ...args);
        }
    }

    public isInfoEnabled() : boolean {
        return this.getLogLevel() >= LogLevel.INFO;
    }

    public info(line: string, ...args : unknown[]): void {
        if (this.isInfoEnabled() && console) {
            line = TinyLog.formatLogLine(LogLevel.INFO, this.ctxt, line);
            console.info(line, ...args);
        }
    }

    public isWarnEnabled() : boolean {
        return this.getLogLevel() >= LogLevel.WARN;
    }

    public warn(line: string, ...args : unknown[]): void {
        if (this.isWarnEnabled() && console) {
            line = TinyLog.formatLogLine(LogLevel.WARN, this.ctxt, line);
            console.warn(line, ...args);
        }
    }

    public isErrorEnabled() : boolean {
        return this.getLogLevel() >= LogLevel.ERROR;
    }

    public error(line: string, ...args : unknown[]): void {
        if (this.isErrorEnabled() && console) {
            line = TinyLog.formatLogLine(LogLevel.ERROR, this.ctxt, line);
            console.error(line, ...args);
        }
    }

    public static formatTimestamp(d : Date) : string {
        // summary:
        //     format a date object for logging purposes. This function
        //     does not need any fancy date formatting API.
        // d: Date|null
        //     A date to format.
        // returns:
        //     The date formatted in 'YYYY-MM-DD hh:mm:ss.SSS' style or
        //     an empty string, if null is passed.
        if (d == null) {
            return "";
        } else {
			return `${formatInt(d.getFullYear(), 4)}-${formatInt(d.getMonth() + 1, 2)}-${formatInt(d.getDate(), 2)} ${formatInt(d.getHours(), 2)}:${formatInt(d.getMinutes(), 2)}:${formatInt(d.getSeconds(), 2)}.${formatInt(d.getMilliseconds(), 3)}`;
        }
    }
}
