/*
 * $Id: $
 *
 * Copyright (C) 2020 ITEG IT-Engineers GmbH
 */

import {TimeStampComponents} from "clazzes-core/dateTime/FancyDate";
import {TinyLog} from "clazzes-core/log/TinyLog";
import {MathHelper} from "clazzes-core/math/MathHelper";

import {ZoneInfos} from "clazzes-core/dateTime/ZoneInfos";

let className : string = "org.clazzes.dateTime.TimeZoneHelper";
let log : TinyLog = new TinyLog(className);

// [Offset to GMT measured in minutes, Rules, ? ("Format"), Valid until measured in UTC seconds, <Stuff used for Valid until>]
type ZoneInfo = [number, string, string, number, ...unknown[]];

// Hour, minute, second, and type according to convertDateToUTC
type HourMinuteSeconds = [number, number, number, string];

// Sample RuleInfo: return [-1000000, 'max', '-', 'Jan', 1, [0, 0, 0], parseInt(staticDstMatch[1],10) * 60 + parseInt(staticDstMatch[2], 10), '-'];
// RuleInfo[1] seems to be a maximum value, or textual 'max'
type RuleInfo = [number, number | string, string, string, number | string, HourMinuteSeconds, number, string];

//     (1) arrays of the form [year, Rule]
//     (2) utcSeconds.
type ApplicableRule = [number, RuleInfo] | number;

export interface Zone1970Entry {
	countries: string[],
	timeZoneName: string,
	comment: string | null,
}

/** This helper class keeps track about time zone information, especially the time zone offsets.  Furthermore,
 *  it offers some helper methods for conversion between utcSeconds and timestamps in the form (year, month, ..., milliSecond).
 *  It loads time zone information from the corresponding Olson files, and caches it appropriately.
 */
export class TimeZoneHelper {
    /** Returns the utc seconds for the given set of year, month, ..., milliSecond, assuming the
     *  given time zone.
     *
     *  @remarks
     *  Any number of the parameters starting at hour, minute, second or milliSecond up to
     *  milliSecond may be missing (default value zero).  In this case, the parameter at
     *  the first missing position is interpreted as timeZone (type String).  If the time zone
     *  is missing also, UTC is taken as a default.
     */
    public static getUtcSeconds(year : number, month : number, date : number,
                                hour? : number | string, minute? : number | string,
                                second? : number | string, milliSecond? : number | string,
                                timeZone? : string) : number {

        if (typeof hour == "string") {
            timeZone = hour;
            hour = null;
        } else if (typeof hour != "undefined") {
            if (typeof minute == "string") {
                timeZone = minute;
                minute = null;
            } else if (typeof minute != "undefined") {
                if (typeof second == "string") {
                    timeZone = second;
                    second = null;
                } else if (typeof second != "undefined") {
                    if (typeof milliSecond == "string") {
                        timeZone = milliSecond;
                        milliSecond = null;
                    }
                }
            }
        }

        if (!timeZone) {
            timeZone = "UTC";
        }

        // Reason for the casts: The string cases were mapped to null above.
        return TimeZoneHelper.getUtcSecondsImpl(year, month, date, <number>hour, <number>minute, <number>second,
            <number>milliSecond, timeZone);
    }

    /** Returns the utc seconds for the given set of year, month, ..., milliSecond, assuming the
     *  given time zone.
     *
     *  @remarks
     *  The difference to the getUtcSeconds function is that in this function, none of the parameter
     *  may be undefined.  However, hour, minute, second or milliSecond may be null, then a default
     *  value of zero is used.
     */
    private static getUtcSecondsImpl(year : number, month : number, date : number,
                                     hour : number, minute : number, second : number, milliSecond : number,
                                     timeZone : string) : number {
        let utcSeconds : number = TimeZoneHelper.getUtcSecondsFromTimeStamp(year, month, date, hour, minute, second, milliSecond);
        let offset : number = TimeZoneHelper.getOffsetForLocalTime(utcSeconds, timeZone);
        return utcSeconds + offset * 60;
    }

    /** Returns the offset for the given utc seconds in the given time zone.
     *
     *  @remarks
     *  The given utc seconds are expected to be the result of converting a given local time timestamp
     *  into utc seconds as if the time zone would be UTC.  Those utc seconds are used for determining
     *  the zone offset in general, ignoring any rules for now.  That offset is added to the given UTC
     *  seconds.  That sum is fed into the algorithm for determining the offset based on zone AND rules.
     *
     *  @param localTimeUtcSeconds Some local time timestamp, converted into UTC seconds as if the time zone would be UTC
     *  @param timeZone any time zone
     *  @returns returns offset of the local time timestamp that was converted into utc seconds, relative to UTC
     */
    private static getOffsetForLocalTime(localTimeUtcSeconds : number, timeZone : string) : number {
        let gmtOffset : number = TimeZoneHelper.getGMTOffset(timeZone);
        if (gmtOffset != null) {
            return gmtOffset;
        } else {
            // First determine the offset ignoring the rules.
            let zoneInfo : ZoneInfo = TimeZoneHelper.getZoneInfo(localTimeUtcSeconds, timeZone);
            let rawOffset : number = zoneInfo[0];
            let utcSecondsForTest : number = localTimeUtcSeconds + rawOffset * 60;

            let timeZoneOffset : number = TimeZoneHelper.getTimeZoneOffset(utcSecondsForTest, timeZone);
            return timeZoneOffset;
        }
    }

    /** Returns the utc date (number of dates since/before
     *  1st January 1970) for the given combination of year/month/date.
     */
    private static getUtcDateFromTimeStamp(year : number, month : number, date : number) : number {
        let julianDay : number;

        if (year < 0) {
            ++year;
        }

        if (year > 1582 || (year == 1582 && (month > 10 || (month == 10 && date >= 15)))) {
            let monthDiv : number = MathHelper.div(month - 14, 12);

            // Gregorian calendar starting from October 15, 1582
            // Algorithm from Henry F. Fliegel and Thomas C. Van Flandern
            julianDay = MathHelper.div(1461 * (year + 4800 + monthDiv), 4)
                + MathHelper.div(367 * (month - 2 - 12 * (monthDiv)), 12)
                - MathHelper.div(3 * ((year + 4900 + monthDiv) / 100), 4)
                + date - 32075;
        } else if (year < 1582 || (year == 1582 && (month < 10 || (month == 10 && date <= 4)))) {
            // Julian calendar until October 4, 1582
            // Algorithm from Frequently Asked Questions about Calendars by Claus Toendering
            let monthDiv : number = MathHelper.div(14 - month, 12);
            julianDay = MathHelper.div(153 * (month + (12 * monthDiv) - 3) + 2, 5)
                + MathHelper.div(1461 * (year + 4800 - monthDiv), 4)
                + date - 32083;
        } else {
            // the day following October 4, 1582 is October 15, 1582
            throw "The date " + year + "-" + month + "-" + date + " does not exist in the Gregorian calendar.";
        }

        // Convert to utc date (1st January 1970 == 0)
        return julianDay - 2440588;
    }

    /** Returns the number of utc seconds for the given time stamp.
     *  hour, minute, second and milliSecond may be null, default value zero.
     */
    private static getUtcSecondsFromTimeStamp(year : number, month : number, date : number,
                                              hour? : number, minute? : number, second? : number, milliSecond? : number) : number {

        let utcDate : number = TimeZoneHelper.getUtcDateFromTimeStamp(year, month, date);

        // Allow these parameters to be missing
        if (!hour) {
            hour = 0;
        }
        if (!minute) {
            minute = 0;
        }
        if (!second) {
            second = 0;
        }
        if (!milliSecond) {
            milliSecond = 0;
        }

        return (((utcDate * 24 + hour) * 60 + minute) * 60 + second) + milliSecond / 1000;
    }

    /** Returns the day of week for the given utc seconds.
     */
    private static getDayOfWeekFromUtcSeconds(utcSeconds : number) : number {
        let utcDate : number = Math.floor(utcSeconds / (24 * 60 * 60));
        // The 1st January 1970 was a Thursday
        return (utcDate + 4) % 7;
    }

    /** Returns a time stamp for the given number of epoch seconds.
     *  IMPORTANT: If you are putting local time (having added the seconds), you may get a different date than in UTC.
     *
     *  The name of the method is missleading but historical.
     *
     *  @param epochSeconds Seconds from 1970-01-01 in the time zone for which you need the date.
     *  @returns {year, month, day, hour, minute, second, milliSecond}
     */
    public static getTimeStampFromUtcSeconds(epochSeconds : number) : TimeStampComponents {
        let year : number;
        let month : number;
        let date : number;

        let secondsOfDate : number = Math.floor(epochSeconds / (24 * 60 * 60));
        let julianDay : number = secondsOfDate + 2440588;

        if (julianDay >= 2299161) {
            // Gregorian calendar starting from October 15, 1582
            // This algorithm is from Henry F. Fliegel and Thomas C. Van Flandern
            let ell : number;
            let n : number;
            let i : number;
            let j : number;

            ell = julianDay + 68569;
            n = MathHelper.div(4 * ell, 146097);
            ell = ell - MathHelper.div(146097 * n + 3, 4);
            i = MathHelper.div(4000 * (ell + 1), 1461001);
            ell = ell - MathHelper.div(1461 * i, 4) + 31;
            j = MathHelper.div(80 * ell, 2447);
            date = ell - MathHelper.div(2447 * j, 80);
            ell = MathHelper.div(j, 11);
            month = j + 2 - (12 * ell);
            year = 100 * (n - 49) + i + ell;
        } else {
            // Julian calendar until October 4, 1582
            // Algorithm from Frequently Asked Questions about Calendars by Claus Toendering
            julianDay += 32082;
            let dd : number = MathHelper.div(4 * julianDay + 3, 1461);
            let ee : number = julianDay - MathHelper.div(1461 * dd, 4);
            let mm : number = MathHelper.div((5 * ee) + 2, 153);
            date = ee - MathHelper.div(153 * mm + 2, 5) + 1;
            month = mm + 3 - 12 * MathHelper.div(mm, 10);
            year = dd - 4800 + MathHelper.div(mm, 10);
            if (year <= 0) {
                year--;
            }
        }

        let dateInternalOffset : number = epochSeconds - secondsOfDate * 24 * 60 * 60;
        let hour : number = Math.floor(dateInternalOffset / (60 * 60));
        dateInternalOffset -= hour * 60 * 60;
        let minute : number = Math.floor(dateInternalOffset / 60);
        dateInternalOffset -= minute * 60;
        let second : number = Math.floor(dateInternalOffset);
        dateInternalOffset -= second;
        let milliSecond : number = dateInternalOffset * 1000;

        return {
            date : date,
            month : month,
            year : year,
            hour : hour,
            minute : minute,
            second : second,
            milliSecond : milliSecond,
        };
    }

    /**************************************************************************************************************/
    /*********************************************** Initialization ***********************************************/
    /**************************************************************************************************************/

    private static defaultZoneFile : string = "europe";

    /** Maps zone names to information about the zone.  The information is expressed by the following array:
     *  [Offset to GMT measured in minutes, Rules, ? ("Format"), Valid until measured in UTC seconds, <Stuff used for Valid until>]
     */
    private static zones : Map<string, ZoneInfo[] | string> = new Map<string, ZoneInfo[] | string>();

    /** Maps rule names to an array with one entry per rule.   That entry itself is an array, with the following contents:
     *  [From Year, To Year or magic String, Type, In, On, At as array [hour, minute, second, modifier],
     * Safe measured in minutes, Letters]
     */
    private static rules : Map<string, RuleInfo[]> = new Map<string, RuleInfo[]>();


    /** loadedZones[zoneFileName] is true if and only if the file was already loaded and its contents successfully processed.
     */
    private static loadedZones : Map<string, boolean> = new Map<string, boolean>();

    private static initialized : boolean = false;

    private static DAYS : string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    private static MONTHS : string[] = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'];

    /** { "Jan": 1, "Feb": 2, "Mar": 3, "Apr": 4, "May": 5, "Jun": 6, "Jul": 7, "Aug": 8, "Sep": 9, "Oct": 10, "Nov": 11, "Dec": 12 }
     */
    private static SHORT_MONTHS : Map<string, number> = new Map<string, number>();

    /** { "Sun": 0, "Mon": 1, "Tue": 2, "Wed": 3, "Thu": 4, "Fri": 5, "Sat": 6 }
     */
    private static SHORT_DAYS : Map<string, number> = new Map<string, number>();

    /** Maps tuples of year and rule name to the appropriate point in time where the rule becomes valid.
     *  Acts as a cache used by the rule comparators.
     */
    private static ruleTimeCache : Map<number, Map<RuleInfo, number>> = new Map<number, Map<RuleInfo, number>>();

    /** Map from Region names given in the time zone names of the
     *  format Region/City to the names of the corresponding Olson
     *  files.
     */
    private static regionMap : Map<string, string> = new Map<string, string>();


    /** Map from time zone names to corresponding Olson file names.
     *  Contains entries for all time zone names where the corresponding
     *  rules etc. are not located in the Olson files the Region pretends.
     */
    private static regionExceptions : Map<string, string> = new Map<string, string>();

	private static zone1970: Zone1970Entry[] | undefined = undefined;

    private static initialize() : void {
        for (let i : number = 0; i < TimeZoneHelper.MONTHS.length; i++) {
            TimeZoneHelper.SHORT_MONTHS.set(TimeZoneHelper.MONTHS[i].substr(0, 3), i + 1);
        }

        for (let i : number = 0; i < TimeZoneHelper.DAYS.length; i++) {
            TimeZoneHelper.SHORT_DAYS.set(TimeZoneHelper.DAYS[i].substr(0, 3), i);
        }

        TimeZoneHelper.regionMap.set('Etc', 'etcetera');
        TimeZoneHelper.regionMap.set('EST', 'northamerica');
        TimeZoneHelper.regionMap.set('MST', 'northamerica');
        TimeZoneHelper.regionMap.set('HST', 'northamerica');
        TimeZoneHelper.regionMap.set('EST5EDT', 'northamerica');
        TimeZoneHelper.regionMap.set('CST6CDT', 'northamerica');
        TimeZoneHelper.regionMap.set('MST7MDT', 'northamerica');
        TimeZoneHelper.regionMap.set('PST8PDT', 'northamerica');
        TimeZoneHelper.regionMap.set('America', 'northamerica');
        TimeZoneHelper.regionMap.set('Pacific', 'australasia');
        TimeZoneHelper.regionMap.set('Atlantic', 'europe');
        TimeZoneHelper.regionMap.set('Africa', 'africa');
        TimeZoneHelper.regionMap.set('Indian', 'africa');
        TimeZoneHelper.regionMap.set('Antarctica', 'antarctica');
        TimeZoneHelper.regionMap.set('Asia', 'asia');
        TimeZoneHelper.regionMap.set('Australia', 'australasia');
        TimeZoneHelper.regionMap.set('Europe', 'europe');
        TimeZoneHelper.regionMap.set('WET', 'europe');
        TimeZoneHelper.regionMap.set('CET', 'europe');
        TimeZoneHelper.regionMap.set('MET', 'europe');
        TimeZoneHelper.regionMap.set('EET', 'europe');

        TimeZoneHelper.regionExceptions.set('Pacific/Honolulu', 'northamerica');
        TimeZoneHelper.regionExceptions.set('Atlantic/Bermuda', 'northamerica');
        TimeZoneHelper.regionExceptions.set('Atlantic/Cape_Verde', 'africa');
        TimeZoneHelper.regionExceptions.set('Atlantic/St_Helena', 'africa');
        TimeZoneHelper.regionExceptions.set('Indian/Kerguelen', 'antarctica');
        TimeZoneHelper.regionExceptions.set('Indian/Chagos', 'asia');
        TimeZoneHelper.regionExceptions.set('Indian/Maldives', 'asia');
        TimeZoneHelper.regionExceptions.set('Indian/Christmas', 'australasia');
        TimeZoneHelper.regionExceptions.set('Indian/Cocos', 'australasia');
        TimeZoneHelper.regionExceptions.set('America/Danmarkshavn', 'europe');
        TimeZoneHelper.regionExceptions.set('America/Scoresbysund', 'europe');
        TimeZoneHelper.regionExceptions.set('America/Godthab', 'europe');
        TimeZoneHelper.regionExceptions.set('America/Thule', 'europe');
        TimeZoneHelper.regionExceptions.set('Antarctica/McMurdo', 'australasia');
        TimeZoneHelper.regionExceptions.set('Asia/Yekaterinburg', 'europe');
        TimeZoneHelper.regionExceptions.set('Asia/Omsk', 'europe');
        TimeZoneHelper.regionExceptions.set('Asia/Novosibirsk', 'europe');
        TimeZoneHelper.regionExceptions.set('Asia/Krasnoyarsk', 'europe');
        TimeZoneHelper.regionExceptions.set('Asia/Irkutsk', 'europe');
        TimeZoneHelper.regionExceptions.set('Asia/Yakutsk', 'europe');
        TimeZoneHelper.regionExceptions.set('Asia/Vladivostok', 'europe');
        TimeZoneHelper.regionExceptions.set('Asia/Sakhalin', 'europe');
        TimeZoneHelper.regionExceptions.set('Asia/Magadan', 'europe');
        TimeZoneHelper.regionExceptions.set('Asia/Kamchatka', 'europe');
        TimeZoneHelper.regionExceptions.set('Asia/Anadyr', 'europe');
        TimeZoneHelper.regionExceptions.set('Africa/Ceuta', 'europe');
        TimeZoneHelper.regionExceptions.set('America/Argentina/Buenos_Aires', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Argentina/Cordoba', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Argentina/Tucuman', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Argentina/La_Rioja', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Argentina/San_Juan', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Argentina/Jujuy', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Argentina/Catamarca', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Argentina/Mendoza', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Argentina/Rio_Gallegos', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Argentina/Ushuaia', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Aruba', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/La_Paz', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Noronha', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Belem', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Fortaleza', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Recife', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Araguaina', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Maceio', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Bahia', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Sao_Paulo', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Campo_Grande', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Cuiaba', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Porto_Velho', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Boa_Vista', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Manaus', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Eirunepe', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Rio_Branco', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Santiago', 'southamerica');
        TimeZoneHelper.regionExceptions.set('Pacific/Easter', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Bogota', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Curacao', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Guayaquil', 'southamerica');
        TimeZoneHelper.regionExceptions.set('Pacific/Galapagos', 'southamerica');
        TimeZoneHelper.regionExceptions.set('Atlantic/Stanley', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Cayenne', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Guyana', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Asuncion', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Lima', 'southamerica');
        TimeZoneHelper.regionExceptions.set('Atlantic/South_Georgia', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Paramaribo', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Port_of_Spain', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Montevideo', 'southamerica');
        TimeZoneHelper.regionExceptions.set('America/Caracas', 'southamerica');

        TimeZoneHelper.loadZoneFile(TimeZoneHelper.defaultZoneFile);
        TimeZoneHelper.initialized = true;
    }

    /**************************************************************************************************************/
    /**************************************** Time zone handling and caching **************************************/

    /**************************************************************************************************************/

    /** Returns the time zone offset (measured in minutes) for the given point in time, in the given time zone.
     */
    public static getTimeZoneOffset(utcSeconds : number, timeZone : string) : number {
        // If timeZone is in the form GMT[+,-]xx:xx, return it right now.
        let gmtOffset : number = TimeZoneHelper.getGMTOffset(timeZone);
        if (gmtOffset != null) {
            return gmtOffset;
        }

        let zoneInfo : ZoneInfo = TimeZoneHelper.getZoneInfo(utcSeconds, timeZone);
        let offset : number = zoneInfo[0];

        // See if the offset needs adjustment.
        let rule : RuleInfo = TimeZoneHelper.getRule(utcSeconds, zoneInfo);
        if (rule) {
            offset = TimeZoneHelper.getAdjustedOffset(offset, rule);
        }
        return offset;
    }

    private static getTimeZoneOffsetFromUtcTimeStamp(year : number, month : number, date : number,
                                                     hour : number, minute : number, second : number,
                                                     milliSecond : number, timeZone : string) {
        let utcSeconds : number = TimeZoneHelper.getUtcSecondsFromTimeStamp(year, month, date, hour, minute, second, milliSecond);
        return TimeZoneHelper.getTimeZoneOffset(utcSeconds, timeZone);
    }

    private static getTimeZoneAbbreviation(utcSeconds : number, timeZone : string) : string {
        let zoneInfo : ZoneInfo = TimeZoneHelper.getZoneInfo(utcSeconds, timeZone);

        // See if the offset needs adjustment.
        let rule : RuleInfo = TimeZoneHelper.getRule(utcSeconds, zoneInfo);
        let abbreviation : string = TimeZoneHelper.getAbbreviation(zoneInfo, rule);

        return abbreviation;
    }

    /** Returns the time zone offset (number of minutes) if the time zone is in form GMT+x:xx or GMT-x:xx.
     *  If the timeZone parameter is not a string of that form, null is returned.
     */
    private static getGMTOffset(timeZone : string) : number {
        if (timeZone && (timeZone.indexOf("GMT+") == 0) || (timeZone.indexOf("GMT-") == 0)) {
            let sign : string = timeZone.charAt(3);
            let postfix : string = timeZone.substring(4);
            let tokens : string[] = postfix.split(":");
            if (tokens.length != 2) {
                return null;
            } else {
                let hour : number = parseInt(tokens[0]);
                let minutes : number = parseInt(tokens[1]);
                let totalMinutes : number = hour * 60 + minutes;
                if (sign == "+") {
                    return -totalMinutes;
                } else if (sign == "-") {
                    return totalMinutes;
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    private static getZoneInfo(utcSeconds : number, timeZone : string) : ZoneInfo {
        if (!this.initialized) {
            this.initialize();
        }

        // Determine the name of the file where the zone is defined
        let zoneFileName : string = TimeZoneHelper.getFileNameForTimezone(timeZone);
        if (!zoneFileName) {
            log.warn("No zone file for zone " + timeZone + " could be determined");
            TimeZoneHelper.processInvalidTimeZoneError(timeZone);

            if (timeZone != "Etc/GMT") {
                return TimeZoneHelper.getZoneInfo(utcSeconds, "Etc/GMT");
            } else {
                throw new Error("Time Zone GMT could not be loaded.");
            }
        }

        // If not yet loaded, load now
        TimeZoneHelper.loadZoneFile(zoneFileName);

        // Then get the zone information --- as we loaded the zone file before, we know
        // for sure that we get an answer here.
        return TimeZoneHelper.getZoneInfoForLoadedZone(utcSeconds, timeZone);
    }

    // TODO Better error handling?
    private static processInvalidTimeZoneError(timeZone : string) : void {
		throw new Error(`Invalid timezone ${timeZone}`);
    }

    /** Given a time zone name in the format Region/City, this
     *  function returns the name of the Olson file where the
     *    rule and zone definitions are located.
     *  Sends message/forUser, if no time zone information could be found for the time zone
     *  Throws an Error, if even the fallback GMT does not work.
     *    @param timeZone
     *  Time zone name in the format Region/City
     *  @returns
     *  File name of the corresponding Olson file.
     */
    private static getFileNameForTimezone(timeZone : string) : string {
        // Some time zones don´t belong to the region they pretend.  If the timeZone at hand is such a case,
        // the regionExceptions map tells us the actual region.
        let fileName : string = TimeZoneHelper.regionExceptions.get(timeZone);
        if (fileName) {
            return fileName;
        }

        // Get the region (name of a region file) from the region specified in the time zone.
        let region : string = timeZone.split('/')[0];
        fileName = TimeZoneHelper.regionMap.get(region);
        if (fileName) {
            return fileName;
        }

        // If there's nothing listed in the main regions for this TZ, check the 'backward' links
        let link : ZoneInfo[] | string = TimeZoneHelper.zones.get(timeZone);
        // Links are in the "backward" file.  Its entries have the syntax
        // Link <destination> <source>.
        // It in particular contains obvious legacy zones (e.g., Iceland) that
        // sometimes don't even have a prefix like "America/" that look like
        // normal zones
        // If it was already loaded, and the link is contained, return the cached destination.
        if (typeof link === 'string') {
            return TimeZoneHelper.getFileNameForTimezone(link);
        }

        // If not yet loaded, load the "backward" file here.
        if (!TimeZoneHelper.loadedZones.get("backward")) {
            TimeZoneHelper.loadZoneFile('backward');
            return TimeZoneHelper.getFileNameForTimezone(timeZone);
        }

        log.warn("No file name for time zone " + timeZone + " could be determined");
        TimeZoneHelper.processInvalidTimeZoneError(timeZone);
        return null;
    }

    /** Returns a member of the this.zones map, containing information about the given time zone
     *  at the given utcSeconds.
     */
    private static getZoneInfoForLoadedZone(utcSeconds : number, timeZone : string) : ZoneInfo {
        // Some time zone names are deprecated, and link to other time zone name.
        // Follow those links to find the actual zone info.
        let zoneInfo : ZoneInfo[] | string = TimeZoneHelper.zones.get(timeZone);
        while (typeof zoneInfo === "string") {
            if (log.isDebugEnabled() && timeZone != "UTC") {
                log.debug("Following link from timeZone " + timeZone + " to " + zoneInfo);
            }
            timeZone = zoneInfo;
            zoneInfo = TimeZoneHelper.zones.get(timeZone);
        }

        // If no zone info could be found, then maybe loading the "backward" file
        // containing links helps.  Load it now.
        if (!zoneInfo) {
            if (!this.loadedZones.get("backward")) {
                //This is for backward entries like "America/Fort_Wayne" that
                // getRegionForTimezone *thinks* it has a region file and zone
                // for (e.g., America => 'northamerica'), but in reality it's a
                // legacy zone we need the backward file for.
                TimeZoneHelper.loadZoneFile('backward');
                return TimeZoneHelper.getZoneInfoForLoadedZone(utcSeconds, timeZone);
            }

            log.warn("No zone info found for timeZone " + timeZone);
            TimeZoneHelper.processInvalidTimeZoneError(timeZone);

            if (timeZone != "Etc/GMT") {
                return TimeZoneHelper.getZoneInfo(utcSeconds, "Etc/GMT");
            } else {
                throw new Error("Could not load zone info for time zone GMT");
            }
        }
        if (zoneInfo.length === 0) {
            log.warn('No Zone found for "' + timeZone + '" on utcSeconds = ' + utcSeconds);
            TimeZoneHelper.processInvalidTimeZoneError(timeZone);

            if (timeZone != "Etc/GMT") {
                return TimeZoneHelper.getZoneInfo(utcSeconds, "Etc/GMT");
            } else {
                throw new Error("Could not load zone info for time zone GMT");
            }
        }

        // Find matching zone info by stepping backward.
        let i : number;
        for (i = zoneInfo.length - 1; i >= 0; i--) {
            let z : ZoneInfo = zoneInfo[i];
            if (z[3] && utcSeconds > z[3]) {
                break;
            }
        }
        return zoneInfo[i + 1];
    }

    private static getAdjustedOffset(off : number, rule : RuleInfo) {
        return -Math.ceil(rule[6] - off);
    }

    private static getAbbreviation(zone : ZoneInfo, rule : RuleInfo) {
        let res : string;
        let base : string = zone[2];
        if (base.indexOf('%s') > -1) {
            let repl : string;
            if (rule) {
                repl = rule[7] === '-' ? '' : rule[7];
            }
                // FIXME: Right now just falling back to Standard --
                // apparently ought to use the last valid rule,
            // although in practice that always ought to be Standard
            else {
                repl = 'S';
            }
            res = base.replace('%s', repl);
        } else if (base.indexOf('/') > -1) {
            // Chose one of two alternative strings.
            res = base.split("/", 2)[rule[6] ? 1 : 0];
        } else {
            res = base;
        }
        return res;
    }

    /**************************************************************************************************************/
    /*************************************************** Rules ****************************************************/

    /**************************************************************************************************************/

    /** Returns the rule to use for the given point in time, in the
     *  time zone given by the given zone info.
     *  @param utcSeconds some point in time
     *  @param zoneInfo Information about the zone.  See documentation of TimeZoneHelper.zones
     *  @returns The matching rule, see documentation of TimeZoneHelper.rules
     */
    private static getRule(utcSeconds : number, zoneInfo : ZoneInfo) : RuleInfo {
        // Determine name of rule to use
        let ruleset : string = zoneInfo[1];
/*        let basicOffset : number = zoneInfo[0];*/

        // If the zone has a DST rule like '1:00', create a rule and return it
        // instead of looking it up in the parsed rules
        let staticDstMatch : RegExpMatchArray = ruleset.match(/^([0-9]):([0-9][0-9])$/);
        if (staticDstMatch) {
            // Return a ever valid rule, thus let it start at year -1000000.
            return [-1000000, 'max', '-', 'Jan', 1, [0, 0, 0, null],
                parseInt(staticDstMatch[1], 10) * 60 + parseInt(staticDstMatch[2], 10), '-'];
        }

        // Find out the year
        let utcTimeStamp : TimeStampComponents = TimeZoneHelper.getTimeStampFromUtcSeconds(utcSeconds);
        let year : number = utcTimeStamp.year;

        // applicableRules is an array, which contains
        //     (1) arrays of the form [year, Rule]
        //     (2) utcSeconds.
        // The former identify points in time where rules are valid, the latter identifies the
        // point in time for which we want to determine the rule to be used.  We do this
        // by sorting the array below.
        let applicableRules : ApplicableRule[] = TimeZoneHelper.findApplicableRules(year, TimeZoneHelper.rules.get(ruleset));

        applicableRules.push(utcSeconds);

        // While sorting, the time zone in which the rule starting time is specified
        // is ignored. This is ok as long as the timespan between two DST changes is
        // larger than the DST offset, which is probably always true.
        // As the given date may indeed be close to a DST change, it may get sorted
        // to a wrong position (off by one), which is corrected below.
        // NOTE: Maybe / hopefully, the latter problem is solved by using UTC seconds instead of the JavaScript Date.
        applicableRules.sort(TimeZoneHelper.compareRuleArrayMembersForSort);

        // If there are not enough past DST rules...
        if (applicableRules.indexOf(utcSeconds) < 2) {
            applicableRules = applicableRules.concat(TimeZoneHelper.findApplicableRules(year - 1, TimeZoneHelper.rules.get(ruleset)));
            applicableRules.sort(TimeZoneHelper.compareRuleArrayMembersForSort);
        }

        let pinpoint : number = applicableRules.indexOf(utcSeconds);
        /* I am not really sure wether cases exist, where the following code is still needed. */
        /*
          if (pinpoint > 1 && this.compareRuleArrayMembersIncludingPreviousRule(utcSeconds,
          applicableRules[pinpoint-1],
          applicableRules[pinpoint-2][1],
          basicOffset) < 0) {

          // The previous rule does not really apply, take the one before that.
          return applicableRules[pinpoint - 2][1];
          } else if (pinpoint > 0 && pinpoint < applicableRules.length - 1
          && this.compareRuleArrayMembersIncludingPreviousRule(utcSeconds,
          applicableRules[pinpoint+1],
          applicableRules[pinpoint-1][1],
          basicOffset) > 0) {

          // The next rule does already apply, take that one.
          return applicableRules[pinpoint + 1][1];
          } else if (pinpoint === 0) {
          //No applicable rule found in this and in previous year.
          return null;
          }*/

        if (pinpoint == 0) {
            // No rule found
            return null;
        } else {
            return applicableRules[pinpoint - 1][1];
        }
    }

    /** Convert a date to UTC. Depending on the 'type' parameter, the date
     *  parameter may be:
     *  - `u`, `g`, `z`: already UTC (no adjustment).
     *  - `s`: standard time (adjust for time zone offset but not for DST)
     *  - `w`: wall clock time (adjust for both time zone and DST offset).
     *  DST adjustment is done using the rule given as third argument.
     */
    private static convertDateToUTC(utcSeconds : number, type : string, rule : RuleInfo, basicOffset : number) : number {
        let offset : number = 0;

        if (type === 'u' || type === 'g' || type === 'z') { // UTC
            offset = 0;
        } else if (type === 's') { // Standard Time
            offset = basicOffset;
        } else if (type === 'w' || !type) { // Wall Clock Time
            offset = TimeZoneHelper.getAdjustedOffset(basicOffset, rule);
        } else {
            throw new Error("unknown type " + type);
        }
        offset *= 60 * 1000; // to millis

        return utcSeconds + offset;
    }

    /** Returns an array with all rules of the given set of rules
     *  for a particular rule name, which are relevant for the given year.
     *  @param year a year
     *  @param ruleset array of rules, see documentation of this.rules.
     *  @returns Array of arrays [year, Rule], where rule is as specified by TimeZoneHelper.rules
     */
    private static findApplicableRules(year : number, ruleset : RuleInfo[]) : ApplicableRule[] {
        let applicableRules : ApplicableRule[] = [];
        for (let i : number = 0; ruleset && i < ruleset.length; i++) {
            //Exclude future rules.
            if (ruleset[i][0] <= year &&
                (
                    // Date is in a set range.
                    ruleset[i][1] >= year ||
                    // Date is in an "only" year.
                    (ruleset[i][0] === year && ruleset[i][1] === "only") ||
                    //We're in a range from the start year to infinity.
                    ruleset[i][1] === "max"
                )
            ) {
                //It's completely okay to have any number of matches here.
                // Normally we should only see two, but that doesn't preclude other numbers of matches.
                // These matches are applicable to this year.
                applicableRules.push([year, ruleset[i]]);
            }
        }
        return applicableRules;
    }

    /** Comparator method for members of the applicableRules array.
     *  @param a Either array of the form [year, Rule] (see this.rules for explanation of Rule), or utcSeconds
     *  @param b Like a.
     *  @returns Usual comparator return value.
     */
    private static compareRuleArrayMembersForSort(a : ApplicableRule, b : ApplicableRule) : number {
        let year : number;
        let rule : RuleInfo;

        if (typeof a != "number") {
            year = a[0];
            rule = a[1];
            a = (TimeZoneHelper.ruleTimeCache.get(year) && TimeZoneHelper.ruleTimeCache.get(year).get(rule))
                ? TimeZoneHelper.ruleTimeCache.get(year).get(rule) : TimeZoneHelper.convertRuleToUtcSeconds(a);
        }
        if (typeof b != "number") {
            year = b[0];
            rule = b[1];
            b = (TimeZoneHelper.ruleTimeCache.get(year) && TimeZoneHelper.ruleTimeCache.get(year).get(rule))
                ? TimeZoneHelper.ruleTimeCache.get(year).get(rule) : TimeZoneHelper.convertRuleToUtcSeconds(b);
        }
        a = Number(a);
        b = Number(b);
        return a - b;
    }

    private static compareRuleArrayMembersIncludingPreviousRule(a : ApplicableRule, b : ApplicableRule,
                                                                prevRule : RuleInfo, basicOffset : number) : number {
        let year : number;
        let rule : RuleInfo;

// private static ruleTimeCache : Map<number, Map<number, number>> = new Map<number, Map<number, number>>();
// type ApplicableRule = [ number, RuleInfo ] | number;

        if (typeof a != "number") {
            year = a[0];
            rule = a[1];
            a = (!prevRule && TimeZoneHelper.ruleTimeCache.get(year) && TimeZoneHelper.ruleTimeCache.get(year).get(rule))
                ? TimeZoneHelper.ruleTimeCache.get(year).get(rule)
                : TimeZoneHelper.convertRuleToUtcSeconds(a, prevRule, basicOffset);
        } else if (prevRule) {
            a = TimeZoneHelper.convertDateToUTC(a, 'u', prevRule, basicOffset);
        }
        if (typeof b != "number") {
            year = b[0];
            rule = b[1];
            b = (!prevRule && TimeZoneHelper.ruleTimeCache.get(year) && TimeZoneHelper.ruleTimeCache.get(year).get(rule))
                ? TimeZoneHelper.ruleTimeCache.get(year).get(rule)
                : TimeZoneHelper.convertRuleToUtcSeconds(b, prevRule, basicOffset);
        } else if (prevRule) {
            b = TimeZoneHelper.convertDateToUTC(b, 'u', prevRule, basicOffset);
        }
        a = Number(a);
        b = Number(b);
        return a - b;
    }

    /** Returns the point in time where the given rule (given by array [year, Rule], see documentation of this.rules)
     *  becomes valid.
     *
     *  @remarks
     *  Step 1:  Find applicable rules for this year.
     *  Step 2:  Sort the rules by effective date.
     *  Step 3:  Check requested date to see if a rule has yet taken effect this year.  If not,
     *  Step 4:  Get the rules for the previous year.  If there isn't an applicable rule for last year, then
     *           there probably is no current time offset since they seem to explicitly turn off the offset
     *           when someone stops observing DST.
     *           FIXME if this is not the case and we'll walk all the way back (ugh).
     *  Step 5:  Sort the rules by effective date.
     *  Step 6:  Apply the most recent rule before the current time.
     *
     *  @params yearAndRule See documentation of this.rules
     */
    private static convertRuleToUtcSeconds(yearAndRule : ApplicableRule, prevRule? : RuleInfo, basicOffset? : number) : number {
        if (basicOffset == null) {
            basicOffset = 0;
        }

        let year : number = yearAndRule[0];
        let rule : RuleInfo = yearAndRule[1];

        let hms : HourMinuteSeconds = rule[5];
        let effectiveUtcSeconds : number;

        // The results of this calculation are cached.
        if (!TimeZoneHelper.ruleTimeCache.get(year)) {
            TimeZoneHelper.ruleTimeCache.set(year, new Map<RuleInfo, number>());
        }

        // Result for given parameters is already stored
        if (TimeZoneHelper.ruleTimeCache.get(year).get(rule)) {
            effectiveUtcSeconds = TimeZoneHelper.ruleTimeCache.get(year).get(rule);
        } else {
            //If we have a specific date, use that!
            if (typeof rule[4] == "number") {
                // Old Js world: !isNaN(rule[4])) {
                effectiveUtcSeconds = TimeZoneHelper.getUtcSecondsFromTimeStamp(year, TimeZoneHelper.SHORT_MONTHS.get(rule[3]),
                    rule[4], hms[0], hms[1], hms[2], 0);
            } else {
                //Let's hunt for the date.
//                let effectiveDate : number;
                let targetDay : number;
                let operator : string;
                //Example: `lastThu`
                if (rule[4].substr(0, 4) === "last") {
                    // Start at the last day of the month and work backward.
                    effectiveUtcSeconds = TimeZoneHelper.getUtcSecondsFromTimeStamp(year,
                        TimeZoneHelper.SHORT_MONTHS.get(rule[3]) + 1,
                        1, hms[0] - 24, hms[1], hms[2], 0);
//                    effectiveDate = 1;
                    targetDay = TimeZoneHelper.SHORT_DAYS.get(rule[4].substr(4, 3));
                    operator = "<=";
                } else {
                    // Example: `Sun>=15`
                    // Start at the specified date.
                    effectiveUtcSeconds = TimeZoneHelper.getUtcSecondsFromTimeStamp(year, TimeZoneHelper.SHORT_MONTHS.get(rule[3]),
                        parseInt(rule[4].substr(5)),
                        hms[0], hms[1], hms[2], 0);
//                    effectiveDate = parseInt(rule[4].substr(5));                 // E.g.: 15
                    targetDay = TimeZoneHelper.SHORT_DAYS.get(rule[4].substr(0, 3)); // E.g.: Sun
                    operator = rule[4].substr(3, 2);                   // E.g.: >=
                }

                // E.g.: The 15th
                let ourDay : number = TimeZoneHelper.getDayOfWeekFromUtcSeconds(effectiveUtcSeconds);

                //Go forwards.
                if (operator === ">=") {
                    // Add the appropriate number of days, recognise possible overflows (e.g. Mon>=15, 15th is Tuesday,
                    // then targetDay-ourDay = 1-2=-1;
                    // -1+7=6; 15th plus 6 days = 21st.
                    effectiveUtcSeconds += (targetDay - ourDay + ((targetDay < ourDay) ? 7 : 0)) * 24 * 60 * 60;
                } else {
                    // Go backwards.  Looking for the last of a certain day, or operator is "<=" (less likely).
                    effectiveUtcSeconds += (targetDay - ourDay - ((targetDay > ourDay) ? 7 : 0)) * 24 * 60 * 60;
                }
            }
            this.ruleTimeCache.get(year).set(rule, effectiveUtcSeconds);
        }


        // If previous rule is given, correct for the fact that the starting time of the current
        // rule may be specified in local time. (e.g. the u or s in 1:00s or 1:00u)
        if (prevRule) {
            effectiveUtcSeconds = TimeZoneHelper.convertDateToUTC(effectiveUtcSeconds, hms[3], prevRule, basicOffset);
        }
        return effectiveUtcSeconds;
    }

    /**************************************************************************************************************/
    /***************************************** Zone file loading and parsing **************************************/
    /**************************************************************************************************************/

    //need to change to requiring the zonefiles into an array and then read from there
    //that is a probably a better approach anyway
    //or we should use some existing library ;)
    // The following files exists at the given location:
    //africa, antartica, asia, astralasia, backward, etcetera, europe, northamerica, pacificnew, southamerica

    private static zoneFileBasePath : string = "/dojo/dojox/date/zoneinfo";

    private static registerZoneInfo(fileName : string, zoneInfo : string) {
        TimeZoneHelper.parseZones(zoneInfo);
        TimeZoneHelper.loadedZones.set(fileName, true);
    }

    /** Loads the time zone information from the given file,
     *  and updates the data structures accordingly.
     *  OLD: Get the file and parse it -- use synchronous XHR.
     *  NEW: includes the file from npm package into webpack bundle (or do it on load time of this javascript).
     *  @param fileName File name of the time zone file, relative to zoneFileBasePath
     */
    private static loadZoneFile(fileName : string) : void {
        if (TimeZoneHelper.loadedZones.get(fileName)) {
            return;
        }

        if (fileName == "africa") {
            TimeZoneHelper.registerZoneInfo(fileName, ZoneInfos.africaZoneInfo);
        } else if (fileName == "antarctica") {
            TimeZoneHelper.registerZoneInfo(fileName, ZoneInfos.antarcticaZoneInfo);
        } else if (fileName == "asia") {
            TimeZoneHelper.registerZoneInfo(fileName, ZoneInfos.asiaZoneInfo);
        } else if (fileName == "australasia") {
            TimeZoneHelper.registerZoneInfo(fileName, ZoneInfos.australasiaZoneInfo);
        } else if (fileName == "backward") {
            TimeZoneHelper.registerZoneInfo(fileName, ZoneInfos.backwardZoneInfo);
        } else if (fileName == "etcetera") {
            TimeZoneHelper.registerZoneInfo(fileName, ZoneInfos.etceteraZoneInfo);
        } else if (fileName == "europe") {
            TimeZoneHelper.registerZoneInfo(fileName, ZoneInfos.europeZoneInfo);
        } else if (fileName == "northamerica") {
            TimeZoneHelper.registerZoneInfo(fileName, ZoneInfos.northamericaZoneInfo);
        }/* else if (fileName == "pacificnew") {
            TimeZoneHelper.registerZoneInfo(fileName, ZoneInfos.pacificnewZoneInfo);
        }*/ else if (fileName == "southamerica") {
            TimeZoneHelper.registerZoneInfo(fileName, ZoneInfos.southamericaZoneInfo);
        }
    }

    /** Processes the contents of a Olson time zone file, and
     *  updates the internal datastructures (rules etc.)
     *  accordingly.
     *  @param str Contents of the file, as a String
     */
    private static parseZones(str : string) : void {
        let lines : string[] = str.split('\n');
        //	let arr : (number | string | number[])[] = [];
        let arr : any[] = [];
        let chunk : string = '';
        let line : string;
        let zoneName : string = null;
//	var rule = null;

        for (let i : number = 0; i < lines.length; i++) {
            line = lines[i];
            // Zone entries may consist of multiple lines, where only
            // the first line has the prefix "Zone".
            if (line.match(/^\s/)) {
                line = "Zone " + zoneName + line;
            }
            // Ignore comments
            line = line.split("#")[0];
            if (line.length > 3) {
                arr = line.split(/\s+/);
                chunk = <string>arr.shift();
                //Ignore Leap.
                switch (chunk) {
                    case 'Zone': {
                        // Consume zone name, process the rest of the array, and insert
                        // the array into the zones map.
                        zoneName = <string>arr.shift();
                        if (!TimeZoneHelper.zones.get(zoneName)) {
                            TimeZoneHelper.zones.set(zoneName, []);
                        }
                        if (arr.length < 3) {
                            break;
                        }

                        // Process zone right here and replace 3rd element (year) with the processed array (time information
                        // condensed in one array [year, month, date, hour, minute, second].
                        arr.splice(3, arr.length, TimeZoneHelper.processZone(<string[]>arr));

                        // Convert into UTC seconds
                        if (arr[3]) {
                            arr[3] = TimeZoneHelper.getUtcSecondsFromTimeStamp.apply(null, arr[3]);
                        }
                        arr[0] = -TimeZoneHelper.getBasicOffset(<string>arr[0]);

                        // Array now: [Offset to GMT measured in minutes, Rules, ? ("Format"), Valid until measured in UTC seconds, <Stuff used for Valid until>]
                        let zoneInfos = <ZoneInfo[]>TimeZoneHelper.zones.get(zoneName);
                        zoneInfos.push(<ZoneInfo>arr);
                        break;
                    }
                    case 'Rule': {
                        // Consume rule name
                        let ruleName : string = <string>arr.shift();

                        // The rules for one rule name are safed in an array
                        if (!TimeZoneHelper.rules.get(ruleName)) {
                            TimeZoneHelper.rules.set(ruleName, []);
                        }
                        //Parse int FROM year and TO year
                        arr[0] = parseInt(arr[0], 10);               // From Year
                        arr[1] = parseInt(arr[1], 10) || arr[1];     // To Year, or one of ("only", ...)

                        // type is used of the day of month for faster computation
                        arr[4] = parseInt(arr[4], 10) || arr[4];

                        //Parse time string AT
                        arr[5] = TimeZoneHelper.parseTimeString(arr[5]);       // At
                        //Parse offset SAVE
                        arr[6] = TimeZoneHelper.getBasicOffset(arr[6]);        // Save offset
                        // Push array [From Year, To Year or magic String, Type, In, On,
                        //             At as array [hour, minute, second, modifier], Safe measured in minutes, Letters]

                        let newRuleInfo : RuleInfo = [ arr[0], arr[1], arr[2], arr[3], arr[4], arr[5], arr[6], arr[7] ];
                        TimeZoneHelper.rules.get(ruleName).push(newRuleInfo);
                        break;
                    }
                    case 'Link':
                        // Links are expressed by the format "destination source", where source delegates to destination.

                        //No zones for these should already exist.
                        if (TimeZoneHelper.zones.get(arr[1])) {
                            throw new Error('Error with Link ' + arr[1] + '. Cannot create link of a preexisted zone.');
                        }
                        //Create the link.
                        TimeZoneHelper.zones.set(arr[1], arr[0]);
                        break;
                }
            }
        }
    }

    /** Processes a zone array loaded from file, and returns the ...
     *  @param zoneArray The contents of a Zone line, loaded from a Olson file, minus the first two tokens
     *                   (Zone line descriptor, zone name).  In detail:
     *                   zoneArray[0]:
     *                   zoneArray[1]: Rule(s) for this zone
     *                   zoneArray[2]:
     *                   zoneArray[3]: Year in format yyyy
     *                   zoneArray[4]: Month as english string, e.g. Jan, may be missing, default 11
     *                   zoneArray[5]: Decimal date, e.g. 5, may be missing, default 31 if month is missing or 1 if month is given
     *                   zoneArray[6]: Time in format [-]hh[:mm[:ss]], may be missing, default 00:00:00
     * @returns An array of length six, [year, month, date, hour, minute, second]
     */
    private static processZone(zoneArray : string[]) : number[] {
        if (!zoneArray[3]) {
            return null;
        }

        let year : number = parseInt(zoneArray[3], 10);
        // according to https://data.iana.org/time-zones/tz-how-to.html the default is year-01-01 00:00
        let month = 1; // was pre-2021: 11
        let date = 1;  // was pre-2021: 31

        if (zoneArray[4]) {
            month = TimeZoneHelper.SHORT_MONTHS.get(zoneArray[4].substr(0, 3));
            date = parseInt(zoneArray[5], 10) || 1;
        }
        const t = zoneArray[6] ? TimeZoneHelper.parseTimeString(zoneArray[6]) : [0, 0, 0];
        return [year, month, date, <number>t[0], <number>t[1], <number>t[2]];
    }

    /** Given a time string from an Olson file, this function returns the corresponding amount of time,
     *  measured in minutes.
     */
    private static getBasicOffset(time : string) : number {
        let parsedContent : (string | number)[] = TimeZoneHelper.parseTimeString(time);
        let adj : number = time.charAt(0) === '-' ? -1 : 1;
        let offset : number = adj * ((<number>parsedContent[0] * 60 + <number>parsedContent[1]) * 60 + <number>parsedContent[2]);
        return offset / 60;
    }

    /** Parses a string of the form [-]hh[:mm[:ss]] and returns an array of length four
     *  containing hour, minute, second, modifier.
     */
    private static parseTimeString(str : string) : (string | number)[] {
        let pattern : RegExp = /(\d+)(?::0*(\d*))?(?::0*(\d*))?([wsugz])?$/;
        let hms : (string | number)[] = str.match(pattern);
        hms[1] = parseInt(<string>hms[1], 10);                 // hours
        hms[2] = hms[2] ? parseInt(<string>hms[2], 10) : 0;    // minutes
        hms[3] = hms[3] ? parseInt(<string>hms[3], 10) : 0;    // seconds
        return hms.slice(1, 5);
    }

	private static parseZone1970Tab(content: string): Zone1970Entry[] {
		const lines = content.split(/\r?\n/);
		// ignore empty lines and comments.
		return lines
			.filter(line => !!line && line[0] !== "#")
			.map(line => {
				const fields = line.split("\t");
				if (fields.length !== 3 && fields.length !== 4) {
					throw new Error(`zone1970.tab contains a line with ${fields.length} fields and not 3 or 4 fields.`);
				}
				const countries = fields[0].split(",");
				// the coordinates field is ignored.
				const timeZoneName = fields[2];

				const comment = fields[3] ?? null;

				return {
					countries,
					timeZoneName,
					comment,
				};
			});
	}

	public static getZone1970(): Zone1970Entry[] {
		if (TimeZoneHelper.zone1970) {
			return TimeZoneHelper.zone1970;
		}
		TimeZoneHelper.zone1970 = TimeZoneHelper.parseZone1970Tab(ZoneInfos.zone1970_tab);
		return TimeZoneHelper.zone1970;
	}
}
