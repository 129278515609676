/*
 * $Id: $
 *
 * Copyright (C) 2020 ITEG IT-Engineers GmbH
 */

import {ParsePosition} from "clazzes-core/math/util/ParsePosition";

export interface NumberFormatParams {
    minLength? : number;
}

export interface NumberParseParams {
    locale? : string,
    parsePosition? : ParsePosition
}

export class MathHelper {
    /** Returns the result of the integer-division a / b.
     */
    public static div(a : number, b : number) : number {
        let c : number = a / b;
        if (c >= 0) {
            return Math.floor(c);
        } else {
            return Math.ceil(c);
        }
    }

    public static hypot(dx : number, dy : number) : number {
        return Math.sqrt(dx * dx + dy * dy);
    }

    public static toRadians(degrees : number) : number {
        return degrees * (2 * Math.PI / 360.0);
    }

    public static exp10(x : number) : number {
        return Math.exp(x / Math.LOG10E);
    }

    public static log10(x : number) : number {
        return Math.log(x) * Math.LOG10E;
    }

    public static sqr(x : number) : number {
        return x * x;
    }

    public static isOdd(n : number) : boolean {
        return n % 2 == 1;
    }

    public static isEven(n : number) : boolean {
        return n % 2 == 0;
    }

    public static min(values : number[]) : number {
        let currMin : number = 1.0e300;
        for (let n : number = 0; n < values.length; n++) {
            if (values[n] < currMin) {
                currMin = values[n];
            }
        }
        return currMin;
    }

    public static max(values : number[]) : number {
        let currMax : number = -1.0e300;
        for (let n : number = 0; n < values.length; n++) {
            if (values[n] > currMax) {
                currMax = values[n];
            }
        }
        return currMax;
    }

    public static sgn(v : number) : number {
        if (v == 0) {
            return 0;
        } else if (v > 0) {
            return 1;
        } else {
            return -1;
        }
    }

    public static toFixed(value : number, precision : number) : number {
        return parseFloat(value.toFixed(precision));
    }

    public static toHexString(value : number, params? : NumberFormatParams) : string {
        if (params == null) {
            params = new Object();
        }

        if (value == null) {
            return "";
        } else {
            let s : string = value.toString(16).toUpperCase();
            while (params.minLength != null && s.length < params.minLength) {
                s = "0" + s;
            }
            return s;
        }
    }

    /** A (incomplete) replacement for
     *     import * as dojoNumber from "dojo/number"
     *     let numberInfo = dojoNumber._parseInfo({ locale : locale });
     *     numberInfo.decimal
     * The sense is getting rid of any dojo import here, to make test cases easier.
     */
    public static getDecimalSeparator(locale : string) : string {
        let numberWithDecimalSeparator = 1.1;
        return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
    }

    // Based on https://stackoverflow.com/questions/638565/parsing-scientific-notation-sensibly
    private static SCIENTIFIC_NUMBER_REG_EXP_DOT   = /^[+-]?(?=\.\d|\d)(?:0|[1-9]\d*)?(?:\.\d*)?(?:[eE][+-]?\d+)?/g;
    private static SCIENTIFIC_NUMBER_REG_EXP_COMMA = /^[+-]?(?=,\d|\d)(?:0|[1-9]\d*)?(?:,\d*)?(?:[eE][+-]?\d+)?/g;

    public static getScientificNumberRegExpMatchesLength(s : string, locale : string) : number {
        let separator : string = (locale != null ? MathHelper.getDecimalSeparator(locale) : null);
        let regExp = null;
        if (separator == null || separator == ".") {
            regExp = MathHelper.SCIENTIFIC_NUMBER_REG_EXP_DOT;
        } else if (separator == ",") {
            regExp = MathHelper.SCIENTIFIC_NUMBER_REG_EXP_COMMA;
        } else {
            throw new Error("Separator [" + separator + "] not yet supported, please add the corresponding RegExp");
        }

        let matches : RegExpMatchArray = s.match(regExp);
        let retValue = matches != null && matches.length != null && matches.length > 0 ? matches[0].length : null;
        return retValue;
    }

    public static parseFloat(s : string, params? : NumberParseParams) : number {
        if (s == null) {
            return null;
        }

        let locale : string = params != null ? params.locale : null;
        let decimalSeparator = MathHelper.getDecimalSeparator(locale);

        let parsePosition : ParsePosition = params.parsePosition;
        let numberWithPostfixString = s.substr(parsePosition != null ? parsePosition.index : 0);
        let matchLength : number = MathHelper.getScientificNumberRegExpMatchesLength(numberWithPostfixString, locale);

        if (matchLength == null) {
            return null;
        } else {
            if (parsePosition != null) {
                parsePosition.index = parsePosition.index + matchLength;
            }

            let numberString : string = numberWithPostfixString.substr(0, matchLength);
            numberString = numberString != null ? numberString.split(decimalSeparator).join(".") : null;
            return Number.parseFloat(numberString);
        }
    }
}
