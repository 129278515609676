/*
 * $Id: $
 *
 * Copyright (C) 2020 ITEG IT-Engineers GmbH
 */

import {TimeZoneHelper} from "clazzes-core/dateTime/TimeZoneHelper";
import {MathHelper} from "clazzes-core/math/MathHelper";

import { Globalize } from "clazzes-core/util/I18n";

export interface FancyDateParams {
    // Clone = Can be used when calling constructor for cloning a FancyDate
    // Components = Can be used when calling constructor with year, month ... components,
    //              and deriving utc seconds from that
    // Utc = Can be used when calling constructor with utc seconds, and deriving
    //       components year, month, ... from that.
    cloned? : boolean;           // Clone
    year? : number;              // Clone, Components
    month? : number;             // Clone, Components
    day? : number;               // Clone, Components
    date? : number;              // Clone, Components
    hour? : number;              // Clone, Components
    minute? : number;            // Clone, Components
    second? : number;            // Clone, Components
    milliSecond? : number;       // Clone, Components
    utcSeconds? : number;        // Clone, Utc
    timeZone? : string;          // Clone, Components, Utc
    utcSecondsDirty? : boolean;  // Clone
}

export interface TimeStampComponents {
    year : number;
    month : number;
    date : number;
    hour : number;
    minute : number;
    second : number;
    milliSecond : number;
}

/** Day of week constants as defined by the plain Javascript Date
 */
export enum DayOfWeek {
    MONDAY    = 1,
    TUESDAY   = 2,
    WEDNESDAY = 3,
    THURSDAY  = 4,
    FRIDAY    = 5,
    SATURDAY  = 6,
    SUNDAY    = 0
}

/** A Date implementation, that has a notion of a time zone, and offers functions for both manipulating
 *  its utc seconds, and time in a component-wise manner (i.e. the components year, month, ..., milliSecond).
 *
 *  @remarks
 *  If either utc seconds or components are altered, the other one is synchronized based on the time zone.
 *
 *  IMPORTANT NOTE: Changes in the components are transferred to the utc seconds in a lazy manner, since
 *      calling multiple functions manipulating the components in a sequence, without asking for utc seconds,
 *      is a frequent usecase.  I.e. those manipulator functions only set the FancyDate dirty, but do not yet
 *      trigger the recalculation of the utc seconds.  That recalculation is e.g. triggered by the function
 *      getUtcSeconds.  Thus it is ESSENTIAL to
 *      (1) only query the utc seconds via getUtcSeconds() and not using the utcSeconds member variable
 *          directly from outside.
 *      (2) not manipulate the components directly from outside.  Use the manipulator functions instead.
 *
 *  NOTE: You can update the utc seconds by calling synchronizeUtcSecondsWithTimeStamp at any time.  However,
 *        this function will only do something if the utc seconds are actually marked dirty by
 *        this.utcSecondsDirty == true, and it is absolutely ESSENTIAL that this condition exists!
 *        (this feature is e.g. needed for DateTimeTextBox.compare, where we have to throw away the laziness, and
 *        request updated utc seconds right now to compare them)
 */
export class FancyDate {

    private year : number;
    private month : number;
    private date : number;
    private hour : number;
    private minute : number;
    private second : number;
    private milliSecond : number;
    private utcSeconds : number;
    private timeZone : string;

    /** The utc seconds are recalculated in a lazy fashion, i.e. only if needed.  This flag is true
     *  if and only if recalculation would be needed if *now* someone would ask for them.
     */
    private utcSecondsDirty : boolean;

    /** Initialize a Date based on the given properties.
     *
     *  @remarks
     *  If cloned == true is passed to this constructor, then it mixes all given parameters into this instance
     *  and does nothing else.  This case is used for cloning a FancyDate.
     *
     *  !cloned is the case to be used for constructing completely new FancyDates.  Here, the constructor has
     *  two modes of operation:  It either receives a utc timestamp and optionally a time
     *  zone, and calculates year, month, day, etc. based on these two parameters, or it receives a set
     *  year, month, day, ..., milliSeconds and a timeZone, and calculates the utc timestamp.
     *
     *  If no utc timestamp is given, hours, minutes, seconds, milliSeconds may be missing (default value zero).
     *  Based on the values year, ..., milliSeconds, and the given time zone, the UTC seconds will be calculated.
     *
     *  In both cases, if the timeZone parameter is omitted, UTC is assumed.
     *
     *  Allow for passing "day" instead of "date", since the class is called "FancyDate", I recognize the
     *  latter a quite unfortunate name.
     */
    constructor(params : FancyDateParams) {
        if (params.cloned != null && params.cloned) {
            this.utcSeconds = params.utcSeconds;
            this.timeZone = params.timeZone;
            this.year = params.year;
            this.month = params.month;
            this.date = params.date;
            this.hour = params.hour;
            this.minute = params.minute;
            this.second = params.second;
            this.milliSecond = params.milliSecond;
            this.utcSecondsDirty = params.utcSecondsDirty;
        } else {
            if (params.utcSeconds != null) {
                this.utcSeconds = params.utcSeconds;
                this.timeZone = params.timeZone != null ? params.timeZone : "UTC";
                this.utcSecondsDirty = false;

                this.synchronizeTimeStampWithUtcSeconds();
            } else {
                this.timeZone = params.timeZone != null ? params.timeZone : "UTC";
                this.year = params.year;
                this.month = params.month;
                if (params.day != null) {
                    this.date = params.day;
                } else {
                    this.date = params.date;
                }
                this.hour = params.hour != null ? params.hour : 0;
                this.minute = params.minute != null ? params.minute : 0;
                this.second = params.second != null ? params.second : 0;
                this.milliSecond = params.milliSecond != null ? params.milliSecond : 0;
                this.utcSecondsDirty = true;

                // Calculate utc seconds in a lazy manner
                this.utcSecondsDirty = true;
            }
        }
    }

    /** Checks wether the components of this date are valid, e.g. do
     *  not contain a 13th month or similar things.
     */
    public isValid() : boolean {
        return this.month >= 1 && this.month <= 12
            && this.date >= 1 && this.date <= FancyDate.getDaysPerMonth(this.year, this.month)
            && (typeof this.hour == "undefined" || (this.hour >= 0 && this.hour <= 23))
            && (typeof this.minute == "undefined" || (this.minute >= 0 && this.minute <= 59))
            && (typeof this.second == "undefined" || (this.second >= 0 && this.second <= 59))
            && (typeof this.milliSecond == "undefined" || (this.milliSecond >= 0 && this.milliSecond <= 999));
    }

    /** Synchronizes the time stamp fields year, month, ..., milliSecond of this instance with utc seconds
     *  and time zone stored in this instance.  I.e. afterwards, they are local time according to that time zone.
     */
    synchronizeTimeStampWithUtcSeconds() : void {
        // Fall back to a default value, to avoid exceptions in getTimeZoneOffset below.
        let utcSeconds : number = this.utcSeconds != null && !Number.isNaN(this.utcSeconds) ? this.utcSeconds : 0;

        // Calculate time zone offset
        let timeZoneOffset : number = TimeZoneHelper.getTimeZoneOffset(utcSeconds, this.timeZone);

        // Calculate seconds since 1.1.1970 in local time
        let utcSecondsLocalTime : number = utcSeconds - timeZoneOffset * 60;

        // Transform those seconds into a time stamp year, month, ..., milliSecond
        let timeStampComponents : TimeStampComponents = TimeZoneHelper.getTimeStampFromUtcSeconds(utcSecondsLocalTime);
        this.year = timeStampComponents.year;
        this.month = timeStampComponents.month;
        this.date = timeStampComponents.date;
        this.hour = timeStampComponents.hour;
        this.minute = timeStampComponents.minute;
        this.second = timeStampComponents.second;
        this.milliSecond = timeStampComponents.milliSecond;
    }

    /** Synchronizes the utc seconds stored in this object with the time stamp fields year, month, ..., milliSecond
     *  stored in this instance, while recognizing the time zone.
     *
     *  @remarks
     *  IMPORTANT: This function only synchronizes the utc seconds with the timestamp, if this.utcSecondsDirty is set.
     *             Synchronizing unconditionally would break e.g. the DateTimeTextBox, in that its compare function then
     *             might transfer a dirty timestamp into the non-dirty utc seconds.
     */
    public synchronizeUtcSecondsWithTimeStamp() : void {
        if (this.utcSecondsDirty) {
            this.utcSeconds = TimeZoneHelper.getUtcSeconds(this.year, this.month, this.date, this.hour,
                this.minute, this.second, this.milliSecond, this.timeZone);
            this.utcSecondsDirty = false;
        } else if (this.utcSeconds == null) {
            // Make sure that we always operate on utcSeconds != null
            this.utcSeconds = 0;
        }
    }

    public getTimeZone() : string {
        return this.timeZone;
    }

    public getTimeZoneOffset() : number {
        this.synchronizeUtcSecondsWithTimeStamp();
        return TimeZoneHelper.getTimeZoneOffset(this.utcSeconds, this.timeZone);
    }

    public getUtcSeconds() : number {
        this.synchronizeUtcSecondsWithTimeStamp();
        return this.utcSeconds;
    }

    public getUtcMillis() : number {
        let utcSeconds : number = this.getUtcSeconds();
        return utcSeconds * 1000;
    }

    public areUtcSecondsDirty() : boolean {
        return this.utcSecondsDirty;
    }

    public addToUtcSeconds(delta : number) : void {
        this.synchronizeUtcSecondsWithTimeStamp();
        this.utcSeconds += delta;
        this.synchronizeTimeStampWithUtcSeconds();
    }

    public setYear(year : number) : void {
        this.year = year;
        this.utcSecondsDirty = true;
    }

    public getYear() : number {
        return this.year;
    }

    public setMonth(month : number) : void {
        this.month = month;
        this.utcSecondsDirty = true;
    }

    public getMonth() : number {
        return this.month;
    }

    public setDate(date : number) : void {
        this.date = date;
        this.utcSecondsDirty = true;
    }

    public getDate() : number {
        return this.date;
    }

    public setDay(day : number) : void {
        this.date = day;
        this.utcSecondsDirty = true;
    }

    public getDay() : number {
        return this.date;
    }

    public setHour(hour : number) : void {
        this.hour = hour;
        this.utcSecondsDirty = true;
    }

    public getHour() : number {
        return this.hour;
    }

    public setMinute(minute : number) : void {
        this.minute = minute;
        this.utcSecondsDirty = true;
    }

    public getMinute() : number {
        return this.minute;
    }

    public setSecond(second : number) : void {
        this.second = second;
        this.utcSecondsDirty = true;
    }

    public getSecond() : number {
        return this.second;
    }

    public setMilliSecond(milliSecond : number) : void {
        this.milliSecond = milliSecond;
        this.utcSecondsDirty = true;
    }

    public getMilliSecond() : number {
        return this.milliSecond;
    }

    public getDayOfWeek() : number {
        let javascriptDate : Date = new Date(this.year, this.month - 1, this.date);
        return javascriptDate.getDay();
    }

    public getDayOfWeekString(locale: string) : string {
        const globalize = Globalize(locale);
        let dayOfWeek : number = this.getDayOfWeek();
        if (dayOfWeek == 0) {
            return globalize.formatMessage("sundayLong");
        } else if (dayOfWeek == 1) {
            return globalize.formatMessage("mondayLong");
        } else if (dayOfWeek == 2) {
            return globalize.formatMessage("tuesdayLong");
        } else if (dayOfWeek == 3) {
            return globalize.formatMessage("wednesdayLong");
        } else if (dayOfWeek == 4) {
            return globalize.formatMessage("thursdayLong");
        } else if (dayOfWeek == 5) {
            return globalize.formatMessage("fridayLong");
        } else if (dayOfWeek == 6) {
            return globalize.formatMessage("saturdayLong");
        } else {
            throw new Error("Illegal day of week: [" + dayOfWeek + "]");
        }
    }

    public getDayOfYear() : number {
        let dayOfYear : number = 0;

        let month : number = this.getMonth();
        for (let n : number = 1; n < month; n++) {
            dayOfYear += FancyDate.getDaysPerMonth(this.year, n);
        }
        dayOfYear += this.getDate();
        return dayOfYear;
    }

    /** Sets the components of this FancyDate to the given values.
     *
     *  @remarks
     *  year, month and date are mandatory, the other parameters may be omitted,
     *  default values zero.
     *  The timeZone is not changed.
     */
    public setComponents(year : number, month : number, date : number,
                         hour : number, minute : number, second : number, milliSecond : number) : void {
        this.year = year;
        this.month = month;
        this.date = date;
        this.hour = (hour ? hour : 0);
        this.minute = (minute ? minute : 0);
        this.second = (second ? second : 0);
        this.milliSecond = (milliSecond ? milliSecond : 0);

        this.utcSecondsDirty = true;
    }

    /** Throw away hours, minutes, second and milliSecond, and let the remaining components
     *  of the date unchanged.
     */
    public truncateToDays() : void {
        // summary:


        this.hour = 0;
        this.minute = 0;
        this.second = 0;
        this.milliSecond = 0;

        this.utcSecondsDirty = true;
    }

    /** Throw away minutes, second and milliSecond, and let the remaining components
     *  of the date unchanged.
     */
    public truncateToHours() : void {
        this.minute = 0;
        this.second = 0;
        this.milliSecond = 0;

        this.utcSecondsDirty = true;
    }

    // ========================================================================================
    // ================= Addition to components ===============================================
    public addComponentWise(delta : Record<string, number>) : void {
        let sign : number = (delta["sign"] != null ? <number>delta["sign"] : 1);
        for (let part in delta) {
            if (part != "sign") {
                this.addToComponent(part, sign * <number>delta[part]);
            }
        }
    }

    /** Adds the given delta to the specified component of this date.
     *  The components are specified as follows: y for years, M for months,
     *  H for hours, m for minutes and s for seconds.
     *  NOTE: For years and months, this function ignores a possible changing time zone
     *        offset, for days, hours, minutes and seconds, in fact the UTC seconds are
     *        increased and then the components are recalculated.
     */
    public addToComponent(component : string, delta : number) : void {
        if (component == "y" || component == "year" || component == "years") {
            this.addYears(delta);
        } else if (component == "M" || component == "month" || component == "months") {
            this.addMonths(delta);
        } else if (component == "d" || component == "date" || component == "days") {
            this.addDays(delta);
        } else if (component == "H" || component == "hour" || component == "hours") {
            this.addHours(delta);
        } else if (component == "m" || component == "minute" || component == "minutes") {
            this.addMinutes(delta);
        } else if (component == "s" || component == "second" || component == "seconds") {
            this.addSeconds(delta);
        } else if (component == "ms" || component == "milliSeconds") {
            this.addMilliSeconds(delta);
        } else {
            throw new Error("addToComponent called with unsupported component: " + component);
        }
    }

    public addYears(delta : number) : void {
        let lastOfMonth : boolean = (this.date == FancyDate.getDaysPerMonth(this.year, this.month));
        this.year += delta;
        // Preserve property "last of month"
        if (lastOfMonth) {
            this.date = FancyDate.getDaysPerMonth(this.year, this.month);
        }
        this.utcSecondsDirty = true;
    }

    public addMonths(delta : number) : void {
        let lastOfMonth : boolean = (this.date == FancyDate.getDaysPerMonth(this.year, this.month));
        if (delta >= 0) {
            if (delta <= 12 - this.month) {
                this.month += delta;
            } else {
                delta -= (13 - this.month);
                this.year++;
                this.month = 1;

                let numberOfYears : number = Math.floor(delta / 12);
                this.year += numberOfYears;
                this.month += (delta - 12 * numberOfYears);
            }
        } else {
            delta = -delta;
            if (delta < this.month) {
                this.month -= delta;
            } else {
                delta -= this.month;
                this.year--;
                this.month = 12;

                let numberOfYears : number = Math.floor(delta / 12);
                this.year -= numberOfYears;
                this.month -= (delta - 12 * numberOfYears);
            }
        }
        // Preserve property "last of month"
        if (lastOfMonth) {
            this.date = FancyDate.getDaysPerMonth(this.year, this.month);
        }
        this.utcSecondsDirty = true;
    }

    public incrementMonth() : void {
        this.addMonths(1);
    }

    public decrementMonth() : void {
        this.addMonths(-1);
    }

    // NOTE: The following two members are defined in the same way also in DateHelper.
    // The reason for this is avoiding a cyclic dependency (DateHelper also needs
    // to deal with FancyDates).

    public static MONTH_DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    public static getDaysPerMonth(year : number, month : number) : number {
        if (month == 2 && this.isLeapYear(year)) {
            return 29;
        }

        return this.MONTH_DAYS[month - 1];
    }

    public static isLeapYear(year : number) : boolean {
        return (year % 4) == 0 && ((year % 100) != 0 || (year % 400) == 0);
    }

    /** Adds the given number of days to this FancyDate.  Works component-wise,
     *  i.e. possibly changing time zone offsets are ignored.  E.g. given that
     *  on 31st March 2013 daylight saving in MEZ started, 31st March 2013 00:00
     *  plus one day is 1st April 2013 00:00 and *not* 1st April 2013 01:00.
     *  offset: Number of days to add
     */
    public addDays(offset : number) : void {
        if (offset >= 0) {
            let daysCurrentMonth : number = FancyDate.getDaysPerMonth(this.year, this.month);
            if (this.date + offset <= daysCurrentMonth) {
                this.date += offset;
            } else {
                offset -= (daysCurrentMonth + 1 - this.date);
                this.date = 1;
                this.incrementMonth();
                while (offset > 0) {
                    daysCurrentMonth = FancyDate.getDaysPerMonth(this.year, this.month);
                    if (offset >= daysCurrentMonth) {
                        offset -= daysCurrentMonth;
                        this.incrementMonth();
                    } else {
                        this.date = offset + 1;
                        offset = 0;
                    }
                }
            }
        } else {
            offset = -offset;
            if (offset < this.date) {
                this.date -= offset;
            } else {
                offset -= this.date;
                this.decrementMonth();
                let daysCurrentMonth : number = FancyDate.getDaysPerMonth(this.year, this.month);
                this.date = daysCurrentMonth;
                while (offset > 0) {
                    if (offset >= daysCurrentMonth) {
                        offset -= daysCurrentMonth;
                        this.decrementMonth();
                        daysCurrentMonth = FancyDate.getDaysPerMonth(this.year, this.month);
                        this.date = daysCurrentMonth;
                    } else {
                        this.date -= offset;
                        offset = 0;
                    }
                }
            }
        }
        this.utcSecondsDirty = true;
    }

    public addHours(offset : number) : void {
        let numberOfDays : number = MathHelper.div(offset, 24);
        this.addDays(numberOfDays);

        if (offset > 0) {
            offset -= numberOfDays * 24;
            // Example: Start 16:00, adding 7/8/9 hours.
            // Result here: 23 < 24; 24 >= 24; 25 >= 24;
            if (this.hour + offset < 24) {
                // Result: 16 + 7 = 23.
                this.hour += offset;
            } else {
                // Offset -= 8; Result = 0 / 1
                offset -= (24 - this.hour);
                // Hours = 0 / 1
                this.hour = offset;
                this.addDays(1);
            }
        } else {
            offset -= numberOfDays * 24;
            if (this.hour + offset >= 0) {
                this.hour += offset;
            } else {
                offset += this.hour;
                this.hour = 24 + offset;
                this.addDays(-1);
            }
        }

        this.utcSecondsDirty = true;
    }

    public addMinutes(delta : number) : void {
        let numberOfHours : number = MathHelper.div(delta, 60);
        this.addHours(numberOfHours);

        if (delta > 0) {
            delta -= numberOfHours * 60;
            if (this.minute + delta <= 59) {
                this.minute += delta;
            } else {
                delta -= (60 - this.minute);
                this.minute = delta;
                this.addHours(1);
            }
        } else {
            delta -= numberOfHours * 60;
            if (this.minute + delta >= 0) {
                this.minute += delta;
            } else {
                delta += this.minute;
                this.minute = 60 + delta;
                this.addHours(-1);
            }
        }

        this.utcSecondsDirty = true;
    }

    public addSeconds(delta : number) : void {
        let numberOfMinutes : number = MathHelper.div(delta, 60);
        this.addMinutes(numberOfMinutes);

        if (delta > 0) {
            delta -= numberOfMinutes * 60;
            if (this.second + delta <= 59) {
                this.second += delta;
            } else {
                delta -= (60 - this.second);
                this.second = delta;
                this.addMinutes(1);
            }
        } else {
            delta -= numberOfMinutes * 60;
            if (this.second + delta >= 0) {
                this.second += delta;
            } else {
                delta += this.second;
                this.second = 60 + delta;
                this.addMinutes(-1);
            }
        }

        this.utcSecondsDirty = true;
    }

    public addMilliSeconds(delta : number) : void {
        let numberOfSeconds : number = MathHelper.div(delta, 1000);
        this.addSeconds(numberOfSeconds);

        if (delta > 0) {
            delta -= numberOfSeconds * 1000;
            if (this.milliSecond + delta <= 999) {
                this.milliSecond += delta;
            } else {
                delta -= (1000 - this.milliSecond);
                this.milliSecond = delta;
                this.addSeconds(1);
            }
        } else {
            delta -= numberOfSeconds * 1000;
            if (this.milliSecond + delta >= 0) {
                this.milliSecond += delta;
            } else {
                delta += this.milliSecond;
                this.milliSecond = 1000 + delta;
                this.addSeconds(-1);
            }
        }

        this.utcSecondsDirty = true;
    }

    /**    Returns wether this date is component-wise before the given date.
     *  E.g. (2012, 8) is before (2013, 3).  Note that in context of changing
     *  time zone offsets the return value of this function can differ from
     *  a simple comparision on utcSeconds level.
     */
    public isComponentWiseBefore(date : FancyDate) : boolean {
        return this.year < date.year
            || (this.year == date.year && (this.month < date.month
                || (this.month == date.month && (this.date < date.date
                    || (this.date == date.date && (this.hour < date.hour
                        || (this.hour == date.hour && (this.minute < date.minute
                            || (this.minute == date.minute && (this.second < date.second
                                || (this.second == date.second && this.milliSecond < date.milliSecond)))))))))));
    }

    public isBefore(date : FancyDate) : boolean {
        this.synchronizeUtcSecondsWithTimeStamp();
        return this.utcSeconds < date.getUtcSeconds();
    }

    public isAfter(date : FancyDate) : boolean {
        this.synchronizeUtcSecondsWithTimeStamp();
        return this.utcSeconds > date.getUtcSeconds();
    }

    public clone() : FancyDate {
        return new FancyDate({
            cloned : true,
            utcSeconds : this.utcSeconds,
            year : this.year,
            month : this.month,
            date : this.date,
            hour : this.hour,
            minute : this.minute,
            second : this.second,
            milliSecond : this.milliSecond,
            timeZone : this.timeZone,
            utcSecondsDirty : this.utcSecondsDirty,
        });
    }
}
