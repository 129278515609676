/*
 * $Id: $
 *
 * Copyright (C) 2020 ITEG IT-Engineers GmbH
 */

export interface NumberParseInfo {
    value : number;
    startIndex : number;
    endIndex : number;
}

export class StringHelper {
    public static isEmpty(s? : string) : boolean {
        return !s || s.length == 0;
    }

    public static getNonNullString(s? : string) : string {
        return s != null ? s : "";
    }

    public static setNullIfEmpty(obj : Record<string, string|null>, attribute : string) : void {
        if (StringHelper.isEmpty(obj[attribute])) {
            obj[attribute] = null;
        }
    }

    public static endsWith(s : string, pattern : string): boolean {
        const lastPos = s.lastIndexOf(pattern);
        return lastPos == s.length - pattern.length;
    }

    /** Meant as replacement for java.lang.Character.isWhitespace(char)
     */
    public static isWhitespace(s : string) : boolean {
        return s != null && s.length > 0 && s.trim().length == 0;
    }

    // TODO: Consider https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Unicode_Property_Escapes
    // ... for the problem of matching unicode characters

    /** Replacement for java.lang.Character.isJavaIdentifierStart(int)
     *  Its implementation is based on the different cases mentioned
     *  in the JavaDoc of that method, however we DO NOT support
     *  every single character, just the usual ones.
     */
    public static isJavaIdentifierStart(c : string) : boolean {
        return StringHelper.isLetter(c)
//            || StringHelper.isTypeLetterNumber(c)
            || StringHelper.isCurrencySymbol(c)
            || StringHelper.isConnectingPunctuationCharacter(c);
    }

    public static isJavaIdentifierPart(c : string) : boolean {
        return StringHelper.isLetter(c)
            || StringHelper.isCurrencySymbol(c)
            || StringHelper.isConnectingPunctuationCharacter(c)
            || StringHelper.isDigit(c);
//            || StringHelper.isNumericLetter(c)
//            || StringHelper.isCombiningMark(c)
//            || StringHelper.isNonSpacingMark(c)
//            || StringHelper.isIdentifierIgnorable(c);
    }

    /** Replacement for java.lang.Character.isLetter(char)
     */
    public static isLetter(c : string) : boolean {
        return /^[a-zA-ZöÖäÄüÜß]$/.test(c);
    }

    private static isDigit(c : string) : boolean {
        return /^[0-9]$/.test(c);
    }

    /*
    private static isTypeLetterNumber(c : string) : boolean {
        return false;
    }

    private static isNumericLetter(c : string) : boolean {
        return false;
    }*/

    private static isCurrencySymbol(c : string) : boolean {
        return /^\$/.test(c);
    }

/*    private static isCombiningMark(c : string) : boolean {
        return false;
    }
*/

/*    private static isNonSpacingMark(c : string) : boolean {
        return false;
    }
*/
    private static isConnectingPunctuationCharacter(c : string) : boolean {
        return /^_/.test(c);
    }
/*
    private static isIdentifierIgnorable(c : string) : boolean {
        return false;
    }
*/
    /** Parses a positive integer number from the given string, and returns an object
     *  {value, begin, end}, where begin and end are indices within the string,
     *  (first and last index of the parsed number in the string)
     *  and value is the parsed integer number.
     *  @param s any string
     *  @param startIndex index of the first character of the to be parsed integer
     *  @param maxLength the maximal number of characters [0-9] to consider for parsing the integer,
     *                   starting at start index.  If any other character is found, parsing is stopped,
     *                   and the already parsed part considered as result.
     *  @returns Object {value, startIndex, endIndex}, containing the parsed Integer value, the
     *           first index (always the given startIndex, unless we improve the implementation to
     *           filter out some characters), and the last index of the parsed integer in the
     *           string (inclusively).
     *           Returns null if no integer could be parsed at the given startIndex.
     */
    public static parsePositiveIntegerNumber(s : string, startIndex : number, maxLength? : number) : NumberParseInfo {
        let currIndex : number;
        for (currIndex = startIndex; currIndex < s.length && (typeof maxLength == "undefined"
            || currIndex < startIndex + maxLength); currIndex++) {
            const character = s.substr(currIndex, 1);
            if (character.search(/[0-9]/) == -1) {
                if (currIndex == startIndex) {
                    return null;
                } else {
                    return {
                        value : parseInt(s.substring(startIndex, currIndex)),
                        startIndex : startIndex,
                        endIndex : currIndex - 1,
                    };
                }
            }
        }

        return {
            value : parseInt(s.substring(startIndex, currIndex)),
            startIndex : startIndex,
            endIndex : currIndex - 1,
        };
    }

    /** Converts the given string to a regexp string (without the "/" at beginning and end) testing
     *  for exactly that string.  I.e. performs the necessary quoting for characters like "."
     *  CAUTION: Currently, only a subset of the relevant characters is implemented CAUTION
     */
    public static convertStringToRegExpString(s : string) : string {
        s = s.replace(".", "\\.");
        return s;
    }

    /** Given a string containing ${foo}-wildcards, this function returns all wildcards present in
     *  that string.
     */
    public static getStringParameterNames(s : string) : string[] {
        let parameterNames : string[] = [];

        let startSearchIndex : number = 0;
        do {
            let startIndex : number = s.indexOf("${", startSearchIndex);
            let endIndex : number = s.indexOf("}", startIndex);

            if (startIndex == -1) {
                startSearchIndex = -1;
            } else if (endIndex == -1) {
                startSearchIndex = startIndex + 2;
            } else {
                const parameterName = s.substring(startIndex + 2, endIndex);
                parameterNames.push(parameterName);

                startSearchIndex = endIndex + 1;
            }

        } while (startSearchIndex != -1 && startSearchIndex < s.length);

        return parameterNames;
    }

    /** Given a string like "abc, foo,cde, bar", this function extracts the list
     *  of tokens, and returns them as a set of (trimmed) strings.
     *  @param s any string
     *  @param separator the separator string, "," in the above example; optional, "," is used as default value
     *  @returns the set of tokens, i.e. retTokens[token] == true if and only if it is in the parameter list
     */
    public static getListTokens(s : string, separator : string) : Set<string> {
        let tokens : Set<string> = new Set<string>();

        if (!separator) {
            separator = ",";
        }

        let currStartIndex : number = 0;
        while (currStartIndex < s.length) {
            let nextSeparatorIndex : number = s.indexOf(separator, currStartIndex);
            let token : string = s.substring(currStartIndex, nextSeparatorIndex != -1 ? nextSeparatorIndex : s.length);
            token = token.trim();
            tokens.add(token);

            if (nextSeparatorIndex == -1) {
                break;
            }
            currStartIndex = nextSeparatorIndex + 1;
        }

        return tokens;
    }

    public static getCommaSeparatedList(tokens : string[], separator? : string) : string {
        if (tokens == null) {
            return "";
        } else {
            if (separator == null) {
                separator = ", ";
            }

            let s : string = "";
            for (let n = 0; n < tokens.length; n++) {
                let token = tokens[n];
                s += (n == 0 ? "" : separator) + token;
            }
            return s;
        }
    }
}
