/*
 * $Id: $
 *
 * Copyright (C) 2020 ITEG IT-Engineers GmbH
 */

import {FancyDate, FancyDateParams} from "clazzes-core/dateTime/FancyDate";
import {StringHelper, NumberParseInfo} from "clazzes-core/util/StringHelper";
import {TokenTag, Token, SymbolToken, parse as parse_cldr} from "clazzes-core/dateTime/CldrPatternParser";

import { Globalize } from "clazzes-core/util/I18n";

enum COMPARE_WEIGHTS {
    year        = 32140800000,
    month       = 2678400000,
    date        = 86400000,
    hour        = 3600000,
    minute      = 60000,
    second      = 1000,
    milliSecond = 1
}

export interface TimeDifference {
    sign? : number,
    years? : number,
    months? : number,
    days? : number,
    hours? : number,
    minutes? : number,
    seconds? : number,
    milliSeconds? : number
}

export interface DateFormatComponent {
    component : string,
    style : string,
    length? : "Short" | "Long" | number,
    minLength? : number
}

export type DateFormatPattern = (string | DateFormatComponent)[];

export interface DateFormatToken {
    /** Begin index of the token */
    begin : number,
    /** End index of the token */
    end : number,
    /** One of year, month, date, hour, minute, second. */
    offsetUnit : string,
    /** Value to be added to the component at hand in "one step" */
    offsetValue : number
}

export class DateHelper {
    /** Returns the current UTC seconds.
     *  @returns The current UTC seconds
     */
    public static getCurrentTimeSeconds() : number {
        return (new Date()).getTime() / 1000;
    }

    public static getCurrentTimeMillis() : number {
        return (new Date()).getTime();
    }

    public static getUtcSeconds(params : FancyDateParams) : number {
        const date = new FancyDate(params);
        return date.getUtcSeconds();
    }

    public static addDeltaToUtcSeconds(utcSeconds : number, timeZone : string, delta : Record<string, number>): number {
        const date : FancyDate = new FancyDate({
            utcSeconds : utcSeconds,
            timeZone : timeZone,
        });
        date.addComponentWise(delta);
        return date.getUtcSeconds();
    }

    public static subtractDeltaFromUtcSeconds(utcSeconds : number, timeZone : string, delta : Record<string, number>) : number {
        const date : FancyDate = new FancyDate({
            utcSeconds : utcSeconds,
            timeZone : timeZone,
        });
        delta["sign"] = -delta["sign"];
        date.addComponentWise(delta);
        delta["sign"] = -delta["sign"];
        return date.getUtcSeconds();
    }

    public static getUtcSecondsDifference(from : number, to : number, timeZone : string) : TimeDifference {
        const dateFrom = new FancyDate({
            utcSeconds : from,
            timeZone : timeZone,
        });
        const dateTo = new FancyDate({
            utcSeconds : to,
            timeZone : timeZone,
        });
        return DateHelper.getDifference(dateFrom, dateTo);
    }

    /** Calculates the difference dateTwo minus dateOne as a time difference
     *  FancyDate.addComponentWise understands.
     *
     *  @remarks
     *  The algorithm steps down from years to milliSeconds, always calculating
     *  the difference in the respective component, plus taking account for
     *  overflows.  E.g. the difference of 1.1.2013 - 31.12.2012 is zero years,
     *  zero months and one day, the difference of 1.2.2013 - 31.12.2011 is
     *  one year, one month and one day.
     *  NOTE: This function works component-wise.  E.g. the difference
     *  2012-03-01 minus 2012-02-23 is 7 days, whereas the difference
     *  2012-03-31 minus 2012-02-23 is 1 month and 8 days, as February 2012 had
     *  29 days, and first the month component is calculated, and *then* in
     *  the first example 01 (st March) plus 29 days (length of February 2012) minus
     *  23 (rd February) is calculated.
     *
     *  NOTE: This function ignores possibly changing time zone offsets.
     *  E.g. in MEZ, the difference from 1st October (with daylight saving) to
     *  1st November (without daylight saving) is exactly one month, *not* one
     *  month and one hour.
     *  @param dateOne some date
     *  @param dateTwo some date
     *  @returns returns The difference dateOne minus dateTwo as described
     */
    public static getDifference(dateOne : FancyDate, dateTwo : FancyDate) : TimeDifference {
        // First calculate sign, and make sure that dateTwo > dateOne afterwards.
        // Note that we calculate "before" based on the components here, not based on UTC
        // seconds, since otherwise we might get strange effects in the context of
        // time zone offset changes.
        let sign : number;
        if (dateTwo.isComponentWiseBefore(dateOne)) {
            const tmp : FancyDate = dateTwo;
            dateTwo = dateOne;
            dateOne = tmp;
            sign = -1;
        } else {
            sign = 1;
        }

        const difference : TimeDifference = new Object();
        difference.sign = sign;

        // As dateTwo > dateOne, dateTwo.year >= dateOne.year
        difference.years = dateTwo.getYear() - dateOne.getYear();

        if (dateTwo.getMonth() < dateOne.getMonth()) {
            // E.g.: Difference 1.1.2013 - 31.12.2012.  Then the above calculation
            // had one year difference as result, but this in fact is not true.
            // Here, we have to decrement the years value calculated above, and
            // calculate the fictive month difference of 1.13.2012 - 31.12.2012.
            difference.years--;
            difference.months = dateTwo.getMonth() + 12 - dateOne.getMonth();
        } else {
            difference.months = dateTwo.getMonth() - dateOne.getMonth();
        }

        if (dateTwo.getDate() < dateOne.getDate()) {
            // Again: Decrement month value, and calculate the fictive difference
            // of 32.12.2012 - 31.12.2012.
            difference.months--;
            difference.days = dateTwo.getDate() + FancyDate.getDaysPerMonth(dateOne.getYear(),
                dateOne.getMonth()) - dateOne.getDate();
        } else {
            difference.days = dateTwo.getDate() - dateOne.getDate();
        }

        if (dateTwo.getHour() < dateOne.getHour()) {
            difference.days--;
            difference.hours = dateTwo.getHour() + 24 - dateOne.getHour();
        } else {
            difference.hours = dateTwo.getHour() - dateOne.getHour();
        }

        if (dateTwo.getMinute() < dateOne.getMinute()) {
            difference.hours--;
            difference.minutes = dateTwo.getMinute() + 60 - dateOne.getMinute();
        } else {
            difference.minutes = dateTwo.getMinute() - dateOne.getMinute();
        }

        if (dateTwo.getSecond() < dateOne.getSecond()) {
            difference.minutes--;
            difference.seconds = dateTwo.getSecond() + 60 - dateOne.getSecond();
        } else {
            difference.seconds = dateTwo.getSecond() - dateOne.getSecond();
        }

        if (dateTwo.getMilliSecond() < dateOne.getMilliSecond()) {
            difference.seconds--;
            difference.milliSeconds = dateTwo.getMilliSecond() + 1000 - dateOne.getMilliSecond();
        } else {
            difference.milliSeconds = dateTwo.getMilliSecond() - dateOne.getMilliSecond();
        }

        return difference;  // Object
    }

    public static addDifferenceToUtcSeconds(utcSeconds : number, timeZone : string, delta : number) : number {
        const date : FancyDate = new FancyDate({
            utcSeconds : utcSeconds,
            timeZone : timeZone,
        });

        let sign : number;

        if (delta < 0.0) {
            sign = -1;
            delta = -delta;
        } else {
            sign = 1;
        }

        let dm : number = Math.floor(delta / (31.0 * 24.0 * 3600.0));
        delta -= dm * 31.0 * 24.0 * 3600.0;

        let dd : number = Math.floor(delta / (24.0 * 3600.0));
        delta -= dd * 24.0 * 3600.0;

        if (dd > 25.0) {
            dd -= 31;
            dm += 1.0;
        }


        const lastDayInMonth : boolean = date.getDate() == FancyDate.getDaysPerMonth(date.getYear(), date.getMonth());
        date.addComponentWise({sign : sign, months : dm});

        if (lastDayInMonth) {
            date.setDate(FancyDate.getDaysPerMonth(date.getYear(), date.getMonth()));
        }

        date.addComponentWise({sign : sign, days : dd});
        date.addComponentWise({sign : sign, milliSeconds : delta * 1000.0});

        return date.getUtcSeconds();
    }

    public static getDifferenceFromSeconds(totalSeconds : number) : TimeDifference {
        const sign : number = totalSeconds >= 0 ? 1 : -1;
        totalSeconds = Math.abs(totalSeconds);

        const seconds : number = totalSeconds % 60;
        const totalMinutes : number = (totalSeconds - seconds) / 60;

        const minutes : number = totalMinutes % 60;
        const totalHours : number = (totalMinutes - minutes) / 60;

        const hours : number = totalHours % 24;
        const totalDays : number = (totalHours - hours) / 24;

        const days : number = totalDays % 31;
        const totalMonths : number = (totalDays - days) / 31;

        const months : number = totalMonths % 12;
        const totalYears : number = (totalMonths - months) / 12;

        const years : number = totalYears;

        return {
            sign : sign,
            seconds : seconds,
            minutes : minutes,
            hours : hours,
            days : days,
            months : months,
            years : years,
        };
    }

    public static formatUtcMillisWithTimeZoneOffset(utcMillis : number, timeZoneOffset : number,
                                                    pattern : DateFormatPattern, locale : string) : string {
        return DateHelper.formatUtcSecondsWithTimeZoneOffset(utcMillis / 1000, timeZoneOffset, pattern, locale);
    }

    /** Formats the given utcSeconds assuming the given timeZoneOffset, using the given pattern.
     *  @param utcSeconds The utcSeconds
     *  @param timeZoneOffset The time zone offset relative to GMT, measured in minutes, may be positive or negative
     *  @param pattern Pattern used for formatting, is a complex object and *not* just a string, see formatDate for
     *                 more details.
     *  @returns formatted string as described
     */
    public static formatUtcSecondsWithTimeZoneOffset(utcSeconds : number, timeZoneOffset : number,
                                                     pattern : DateFormatPattern, locale : string): string {
        let timeZone : string = "GMT";
        timeZone += (timeZoneOffset < 0 ? "-" : "+");
        const absTimeZoneOffset : number = Math.abs(timeZoneOffset);
        const hours : number = Math.floor(absTimeZoneOffset / 60);
        const minutes : number = Math.floor(absTimeZoneOffset % 60);
        timeZone += hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, "0");

        const date : FancyDate = new FancyDate({
            utcSeconds : utcSeconds,
            timeZone : timeZone,
        });
        return this.formatDate(date, pattern, locale);
    }

    public static formatUtcMillisWithTimeZone(utcMillis : number, timeZone : string, pattern : DateFormatPattern, locale : string): string {
        return DateHelper.formatUtcSecondsWithTimeZone(utcMillis / 1000, timeZone, pattern, locale);
    }

    public static formatUtcSecondsWithTimeZone(utcSeconds : number, timeZone : string, pattern : DateFormatPattern, locale : string): string {
        const date : FancyDate = new FancyDate({
            utcSeconds : utcSeconds,
            timeZone : timeZone,
        });
        return this.formatDate(date, pattern, locale);
    }

    private static isDateFormatComponent(component : string | DateFormatComponent) : component is DateFormatComponent {
        return !(typeof component === "string");
    }

    /** Compare dates, using the given pattern.  Note that pattern is an array of option objects
     *  (or constant strings), not a string.  Each of the array members specifies one part of the string
     *  generated by this function.  Those parts are then concatenated in the order of the array.
     *  @param pattern An array of option objects or strings described in detail in the documentation for the
     *                 function DateHelper.formatDate()
     *  @returns A number greater than, equals or less than zero,
     *           if date1 is greater than equal to or smaller than date2
     *           up to the precision required by the given pattern.
     */
    public static compareDates(date1 : FancyDate, date2 : FancyDate, pattern : DateFormatPattern) : number {
        let lin1 : number = 0;
        let lin2 : number = 0;

        for (let n = 0; n < pattern.length; n++) {
            if (DateHelper.isDateFormatComponent(pattern[n])) {
                // Case DateFormatComponent
                const component = (<DateFormatComponent>pattern[n]).component;

                const weight : number = COMPARE_WEIGHTS[component];

                if (weight) {
                    lin1 += weight * date1[component];
                    lin2 += weight * date2[component];
                }
            }
        }
        return lin1 - lin2;
    }

    /** Formats the given day, using the given pattern.  Note that pattern is an array of option objects
     *  (or constant strings), not a string.  Each of the array members specifies one part of the string
     *  generated by this function.  Those parts are then concatenated in the order of the array.
     *
     *  @param pattern An array of option objects or strings.  If an array member is a string, it is
     *                 added as it is to the output string.   If not, it is an option object.  Each
     *                 of those objects has a property named "component".  It determines which kind
     *                 of data to output.  Depending on the value of "component", more properties are
     *                 defined.
     *
     *                 An example: For the pattern array
     *                     [{component : "month", style : "string", length : 3}, " ",
     *                      {component : "year", style : "decimal", length : 4}],
     *                 the string "Aug 2013" might be generated.
     *
     *                 The mandatory property "style" specifies the style of output.  The set of
     *                 possible styles is component-specific.  The following styles are defined:
     *                     - string: Print as a string, e.g. "August"
     *                     - decimal: Print as an integer, e.g. "8"
     *
     *                 The property "length" specifies the length of the string to be printed for
     *                 a particular component.
     *
     *                 For style "decimal", the length is numeric.  If the length is omitted, the
     *                 decimal number will be printed regardless how long it is.  If a length is
     *                 given, the decimal number will be filled up with "0" to that length if
     *                 necessary (but decimal numbers longer than that length will still be printed
     *                 completely).
     *                 Furthermore, in decimal style, a numeric minLength can be given.  It is used
     *                 for validation purposes, i.e. enforcing that the component has a least this
     *                 number of characters in input.
     *
     *                 For style "string", the length is a string, and component-specific.  It defines,
     *                 from which set of strings to choose the resulting string.
     *
     *                 The following components are supported: year, month, date, hour, minute and second.
     *                 For component month, styles decimal and string are supported.
     *                 For all other components, just style decimal is supported.
     *  @param tokenInfos An initially empty array, used for passing token information to the caller.
     *                 The instances inserted into the array have the fields
     *                     - begin: Begin index of the token
     *                     - end: End index of the token
     *                     - offsetUnit: One of year, month, date, hour, minute, second.
     *                     - offsetValue: Value to be added to the component at hand in "one step"
     *                 The parameter can be omitted, then simply no token information will be recorded.
     *  @returns Formatted date as described
     */
    public static formatDate(date : FancyDate, pattern : DateFormatPattern, locale : string, tokenInfos? : DateFormatToken[]): string {


        if (tokenInfos != null && tokenInfos.length > 0) {
            throw new Error("formatDate expects an empty tokenInfo array it wants to fill with information.");
        }

        // Utc seconds plus time zone define which date the date represents.

        // Synchronize the time stamp with the utc seconds, if the utc seconds are currently the master.
        if (!date.areUtcSecondsDirty()) {
            date.synchronizeTimeStampWithUtcSeconds();
        }

        let generatedString : string = "";
        for (let n : number = 0; n < pattern.length; n++) {
            if (typeof pattern[n] == "string") {
                generatedString += pattern[n];
            } else {
                const currPattern : DateFormatComponent = <DateFormatComponent>pattern[n];
                const component : string = currPattern.component;
                const style : string = currPattern.style;
                const length : string | number = currPattern.length;
                let minLength : number = currPattern.minLength;

                if (component == "year") {
                    if (style == "decimal") {
                        let yearString : string;
                        if (length == "Short") {
                            yearString = (date.getYear() % 100).toString();
                            if (minLength == null) {
                                minLength = 2;
                            }
                        } else {
                            yearString = date.getYear().toString();
                        }
                        let filledUpString : string = this.fillUpString(yearString, minLength, "0");
                        if (tokenInfos) {
                            tokenInfos.push({
                                begin : generatedString.length, end : generatedString.length + filledUpString.length,
                                offsetUnit : "year",
                                offsetValue : 1,
                            });
                        }
                        generatedString += filledUpString;
                    }
                } else if (component == "month") {
                    if (style == "decimal") {
                        const monthString : string = date.getMonth().toString();
                        const filledUpString : string = this.fillUpString(monthString, (minLength != null ? minLength : 2), "0");
                        if (tokenInfos) {
                            tokenInfos.push({
                                begin : generatedString.length, end : generatedString.length + filledUpString.length,
                                offsetUnit : "month",
                                offsetValue : 1,
                            });
                        }
                        generatedString += filledUpString;
                    } else if (style == "string") {
                        const month : number = date.getMonth();
                        let monthString : string;
                        if (length == "Long") {
                            monthString = this.getLongMonthString(month, locale);
                        } else if (length == "Short") {
                            monthString = this.getShortMonthString(month, locale);
                        } else {
                            throw new Error("Illegal length for month in style string: " + length);
                        }
                        if (tokenInfos) {
                            tokenInfos.push({
                                begin : generatedString.length,
                                end : generatedString.length + monthString.length,
                                offsetUnit : "month",
                                offsetValue : 1,
                            });
                        }
                        generatedString += monthString;
                    }
                } else if (component == "date") {
                    if (style == "decimal") {
                        const dateString : string = date.getDate().toString();
                        const filledUpString : string = this.fillUpString(dateString, (minLength != null ? minLength : 2), "0");
                        if (tokenInfos) {
                            tokenInfos.push({
                                begin : generatedString.length,
                                end : generatedString.length + filledUpString.length,
                                offsetUnit : "date",
                                offsetValue : 1,
                            });
                        }
                        generatedString += filledUpString;
                    }
                } else if (component == "hour") {
                    if (style == "decimal") {
                        const hourString : string = date.getHour().toString();
                        const filledUpString : string = this.fillUpString(hourString, (minLength != null ? minLength : 2), "0");
                        if (tokenInfos) {
                            tokenInfos.push({
                                begin : generatedString.length,
                                end : generatedString.length + filledUpString.length,
                                offsetUnit : "hour",
                                offsetValue : 1,
                            });
                        }
                        generatedString += filledUpString;
                    }
                } else if (component == "minute") {
                    if (style == "decimal") {
                        const minuteString : string = date.getMinute().toString();
                        const filledUpString : string = this.fillUpString(minuteString, (minLength != null ? minLength : 2), "0");
                        if (tokenInfos) {
                            tokenInfos.push({
                                begin : generatedString.length,
                                end : generatedString.length + filledUpString.length,
                                offsetUnit : "minute",
                                offsetValue : 1,
                            });
                        }
                        generatedString += filledUpString;
                    }
                } else if (component == "second") {
                    if (style == "decimal") {
                        const secondString : string = date.getSecond().toString();
                        const filledUpString : string = this.fillUpString(secondString, (minLength != null ? minLength : 2), "0");
                        if (tokenInfos) {
                            tokenInfos.push({
                                begin : generatedString.length,
                                end : generatedString.length + filledUpString.length,
                                offsetUnit : "second",
                                offsetValue : 1,
                            });
                        }
                        generatedString += filledUpString;
                    }
                } else if (component == "milliSecond") {
                    if (style == "decimal") {
                        const milliSecondString : string = Math.round(date.getMilliSecond()).toString();
                        const filledUpString : string = this.fillUpString(milliSecondString, (minLength != null ? minLength : 3), "0");
                        if (tokenInfos) {
                            tokenInfos.push({
                                begin : generatedString.length,
                                end : generatedString.length + filledUpString.length,
                                offsetUnit : "milliSecond",
                                offsetValue : 1,
                            });
                        }
                        generatedString += filledUpString;
                    }
                }
            }
        }

        return generatedString;
    }

	private static readonly symbolMapping: {[index: string]: DateFormatComponent} = {
		y: {component: "year", style: "decimal"},
		yy: {component: "year", style: "decimal", length: "Short"},
		"y+": {component: "year", style: "decimal"},
		M: {component: "month", style: "decimal"},
		MM: {component: "month", style: "decimal", minLength: 2},
		MMM: {component: "month", style: "string", length: "Short"},
		MMMM: {component: "month", style: "string", length: "Long"},
		d: {component: "date", style: "decimal", minLength: 1},
		dd: {component: "date", style: "decimal", minLength: 2},
		H: {component: "hour", style: "decimal", minLength: 1},
		HH: {component: "hour", style: "decimal", minLength: 2},
		m: {component: "minute", style: "decimal", minLength: 1},
		mm: {component: "minute", style: "decimal", minLength: 2},
		s: {component: "second", style: "decimal", minLength: 1},
		ss: {component: "second", style: "decimal", minLength: 2},
	};

	// WARNING: These two function are mirrored in dojo-clazzes.
	private static convertCldrSymbol(symbol: SymbolToken): DateFormatComponent
	{
		const specific = DateHelper.symbolMapping[symbol.character.repeat(symbol.length)];
		if (specific) {
			return specific;
		} else {
			const general = DateHelper.symbolMapping[symbol.character + "+"];
			if (!general) {
				return general;
			}
			general.minLength = symbol.length;
			return general;
		}
	}

	public static convertCldrPattern(pattern: Token[]|string): DateFormatPattern
	{
		const tokens = typeof pattern === "string" ? parse_cldr(pattern) : pattern;
		const ret = [];
		for (const token of tokens) {
			if (token.id === TokenTag.LiteralString) {
				ret.push(token.content);
			} else if (token.id === TokenTag.SymbolToken) {
				const component = DateHelper.convertCldrSymbol(token);
				if (!component) {
					throw new Error(
						`Symbol ${token.character.repeat(token.length)} is unsupported`);
				}
				ret.push(component);
			}
		}
		return ret;
	}

	public static ISO_DATE_FORMAT: DateFormatPattern = [
		{component: "year", style: "decimal", minLength: 4},
		"-",
		{component: "month", style: "decimal", minLength: 2},
		"-",
		{component: "date", style: "decimal", minLength: 2},
	];

	public static ISO_TIME_FORMAT: DateFormatPattern = [
		{component: "hour", style: "decimal", minLength: 2},
		":",
		{component: "minute", style: "decimal", minLength: 2},
		":",
		{component: "second", style: "decimal", minLength: 2},
	];

	public static ISO_FORMAT: DateFormatPattern = [
		...DateHelper.ISO_DATE_FORMAT,
		"T",
		...DateHelper.ISO_TIME_FORMAT,
	];

	/** Returns a regular expression testing wether some string matches the given pattern.
     *  @param pattern Pattern, is a complex object rather than a string, see the comment
     *                 for formatDate for more details.
     *  @returns Regular expression as a string
     */
    public static getRegExpForDatePattern(pattern : DateFormatPattern) : string {
        let regExp : string = "";
        for (let n : number = 0; n < pattern.length; n++) {
            const patternComponent : string | DateFormatComponent = pattern[n];
            if (typeof patternComponent == "string") {
                regExp += StringHelper.convertStringToRegExpString(patternComponent);
            } else if (patternComponent.style == "decimal") {
                if (typeof patternComponent.minLength == "undefined") {
                    regExp += "[0-9]+";
                } else {
                    regExp += "[0-9]{" + patternComponent.minLength + ",}";
                }
            } else {
                throw new Error("getRegExpForDatePattern says: Style " + patternComponent.style + " not yet implemented.");
            }
        }
        return regExp;
    }

    /** Returns a descriptive string in the usual style (e.g. yyyy-MM-dd) for the given pattern.
     *  @param pattern Some pattern, see comment for formatDate for detailed information
     *  @returns Descriptive pattern string as described
     */
    public static datePatternToString(pattern : DateFormatPattern) : string {
        let s : string = "";
        for (let n : number = 0; n < pattern.length; n++) {
            const patternComponent : string | DateFormatComponent = pattern[n];
            if (typeof patternComponent == "string") {
                s += patternComponent;
            } else if (patternComponent.style == "decimal") {
                if (patternComponent.component == "year") {
                    s += "yyyy";
                } else if (patternComponent.component == "month") {
                    s += "MM";
                } else if (patternComponent.component == "date") {
                    s += "dd";
                } else if (patternComponent.component == "hour") {
                    s += "hh";
                } else if (patternComponent.component == "minute") {
                    s += "mm";
                } else if (patternComponent.component == "second") {
                    s += "ss";
                } else {
                    throw new Error("datePatternToString says: " + patternComponent.component + " not yet implemented.");
                }
            } else {
                throw new Error("datePatternToString says: Style " + patternComponent.style + " not yet implemented.");
            }
        }
        return s;
    }

    /** Returns the default length for the given component of a pattern, assuming decimal output
     *  @param component some component of a pattern as described in the comment of formatDate
     *  @returns default length as described
     */
    private static getDefaultLength(component : string) : number {
        if (component == "year") {
            return 4;
        } else if (component == "month" || component == "date"
            || component == "hour" || component == "minute" || component == "second") {
            return 2;
        } else {
            throw new Error("Unsupported component.");
        }
    }

    /** Parses the given string according to the given pattern.
     *
     *  @remarks
     *  If the string is malformed then null is returned, otherwise a FancyDate
     *  holding the string, in the given time zone.  Malformed can either mean a
     *  incorrectly formatted string, or a invalid combination of the date components,
     *  e.g. month == 13.
     *  This function expects year, month and date in the pattern (and thus also
     *  in the string).  The other components may be missing, but not in between.
     *  E.g. year, month, date, minute would be illegal.
     *  If one component is present multiple times in the string
     *  (e.g. year month year), the result is not defined.
     *  @param s some string
     *  @param pattern Pattern as specified in the description of formatDate
     *  @param timeZone Time zone
     *  @returns FancyDate holding the date given by the string, or null if the
     *           string was not valid with respect to the given pattern.
     */
    public static parseToDate(s : string, pattern : DateFormatPattern,
                              timeZone : string, tokenInfos? : DateFormatToken[]) : FancyDate {

        if (tokenInfos != null && tokenInfos.length > 0) {
            throw new Error("parseToDate expects an empty tokenInfo array it wants to fill with information.");
        }

        // begin, end, offsetUnit, offsetValue

        const regExp : RegExp = new RegExp("^" + DateHelper.getRegExpForDatePattern(pattern) + "$");
        if (s.search(regExp) == -1) {
            return null;
        } else {
            let year : number = undefined;
            let month : number = undefined;
            let date : number = undefined;
            let hour : number = undefined;
            let minute : number = undefined;
            let second : number = undefined;
            let milliSecond : number = undefined;

            let currIndex : number = 0;
            for (let n : number = 0; n < pattern.length; n++) {
                const patternComponent : string | DateFormatComponent = pattern[n];
                if (typeof patternComponent == "string") {
                    if (s.substr(currIndex, patternComponent.length) != patternComponent) {
                        // In fact, this case should not happen since we have a RegExp test above
                        // that should catch these kinds of errors (wrong string literal in the
                        // to be tested string).
                        return null;
                    } else {
                        currIndex += patternComponent.length;
                    }
                } else if (patternComponent.style == "decimal") {
                    const parseResult : NumberParseInfo = StringHelper.parsePositiveIntegerNumber(s, currIndex);
                    if (!parseResult) {
                        throw new Error("Could not parse integer value for patternComponent " + patternComponent.component
                            + " at currIndex " + currIndex + " of string " + s);
                    }
                    if (tokenInfos != null) {
                        const tokenInfo : DateFormatToken = {
                            begin : parseResult.startIndex,
                            end : parseResult.endIndex + 1,
                            offsetUnit : patternComponent.component,
                            offsetValue : 1
                        };
                        tokenInfos.push(tokenInfo);
                    }

                    if (patternComponent.component == "year") {
                        year = parseResult.value;
                    } else if (patternComponent.component == "month") {
                        month = parseResult.value;
                    } else if (patternComponent.component == "date") {
                        date = parseResult.value;
                    } else if (patternComponent.component == "hour") {
                        hour = parseResult.value;
                    } else if (patternComponent.component == "minute") {
                        minute = parseResult.value;
                    } else if (patternComponent.component == "second") {
                        second = parseResult.value;
                    } else if (patternComponent.component == "milliSecond") {
                        milliSecond = parseResult.value;
                    } else {
                        throw new Error("Unsupported patternComponent: " + patternComponent.component);
                    }

                    currIndex = parseResult.endIndex + 1;
                }
            }
            const fancyDate : FancyDate = new FancyDate({
                year : year,
                month : (month != null ? month : 1),
                date : (date != null ? date : 1),
                hour : (hour ? hour : 0),
                minute : (minute ? minute : 0),
                second : (second ? second : 0),
                milliSecond : (milliSecond ? milliSecond : 0),
                timeZone : timeZone,
            });
            return (fancyDate.isValid() ? fancyDate : null);
        }
    }

    /** Fills up the given string to the given length, by adding the appropriate number of
     *  occurrences of the given character to the left.
     *  @param string any String
     *  @param desiredLength the desired length
     *  @param character the character to be used for filling up
     *  @returns the resulting string
     */
    private static fillUpString(s : string, desiredLength : number, character : string) : string {
        if (desiredLength) {
            while (s.length < desiredLength) {
                s = character + s;
            }
        }
        return s;
    }

    /** Returns a long month string for the given month, where long means long in the sense of
     *  the patterns defined in the description of formatDate
     *  @param month Month, 1 for January and 12 for December
     *  @param locale Optional locale, if not given, the user's locale is used.
     *  @returns String for the given month as described
     */
    public static getLongMonthString(month : number, locale : string) : string {
        const globalize = Globalize(locale);

        switch (month) {
            case 1:
                return globalize.formatMessage("januaryLong");
            case 2:
                return globalize.formatMessage("februaryLong");
            case 3:
                return globalize.formatMessage("marchLong");
            case 4:
                return globalize.formatMessage("aprilLong");
            case 5:
                return globalize.formatMessage("mayLong");
            case 6:
                return globalize.formatMessage("juneLong");
            case 7:
                return globalize.formatMessage("julyLong");
            case 8:
                return globalize.formatMessage("augustLong");
            case 9:
                return globalize.formatMessage("septemberLong");
            case 10:
                return globalize.formatMessage("octoberLong");
            case 11:
                return globalize.formatMessage("novemberLong");
            case 12:
                return globalize.formatMessage("decemberLong");
            default:
                throw new Error("Invalid month: " + month);
        }
    }

    /** Returns a short month string for the given month, where short means short in the sense of
     *  the patterns defined in the description of formatDate
     *  @param month Month, 1 for January and 12 for December
     *  @param locale Optional locale, if not given, the user's locale is used.
     *  @returns String for the given month as described
     */
    public static getShortMonthString(month : number, locale : string) : string {
        const globalize = Globalize(locale);

        switch (month) {
            case 1:
                return globalize.formatMessage("januaryShort");
            case 2:
                return globalize.formatMessage("februaryShort");
            case 3:
                return globalize.formatMessage("marchShort");
            case 4:
                return globalize.formatMessage("aprilShort");
            case 5:
                return globalize.formatMessage("mayShort");
            case 6:
                return globalize.formatMessage("juneShort");
            case 7:
                return globalize.formatMessage("julyShort");
            case 8:
                return globalize.formatMessage("augustShort");
            case 9:
                return globalize.formatMessage("septemberShort");
            case 10:
                return globalize.formatMessage("octoberShort");
            case 11:
                return globalize.formatMessage("novemberShort");
            case 12:
                return globalize.formatMessage("decemberShort");
            default:
                throw new Error("Invalid month: " + month);
        }
    }

    public static before(dateOne : FancyDate, dateTwo : FancyDate) : boolean {
        if (dateOne.getYear() != dateTwo.getYear()) {
            return dateOne.getYear() < dateTwo.getYear();
        } else if (dateOne.getMonth() != dateTwo.getMonth()) {
            return dateOne.getMonth() < dateTwo.getMonth();
        } else if (dateOne.getDate() != dateTwo.getDate()) {
            return dateOne.getDate() < dateTwo.getDate();
        } else if (dateOne.getHour() != dateTwo.getHour()) {
            return dateOne.getHour() < dateTwo.getHour();
        } else if (dateOne.getMinute() != dateTwo.getMinute()) {
            return dateOne.getMinute() < dateTwo.getMinute();
        } else if (dateOne.getSecond() != dateTwo.getSecond()) {
            return dateOne.getSecond() < dateTwo.getSecond();
        } else {
            return dateOne.getMilliSecond() < dateTwo.getMilliSecond();
        }
    }

    public static getEaster(year : number, timeZone : string) : FancyDate {
        const gg : number = year % 19;
        const cc : number = Math.floor(year / 100);
        // (cc - cc / 4 - (8 * cc + 13) / 25 + 19 * gg + 15) % 30;
        const hh : number = (cc - Math.floor(cc / 4) - Math.floor((8 * cc + 13) / 25) + 19 * gg + 15) % 30;
        // hh - hh / 28 * (1 - (29 / (hh + 1)) * ((21 - gg) / 11));
        const ii : number = hh - Math.floor(hh / 28) * (1 - Math.floor(29 / (hh + 1)) * Math.floor((21 - gg) / 11));
        // (year + year / 4 + ii + 2 - cc + cc / 4) % 7;
        const jj : number = (year + Math.floor(year / 4) + ii + 2 - cc + Math.floor(cc / 4)) % 7;
        const ll : number = ii - jj;
        // 3 + (ll + 40) / 44;
        const month : number = 3 + Math.floor((ll + 40) / 44);
        // ll + 28 - 31 * (month / 4);
        const day : number = ll + 28 - 31 * Math.floor(month / 4);

        return new FancyDate({
            year : year,
            month : month,
            day : day,
            timeZone : timeZone,
        });
    }

    public static addToDayOfWeek(dayOfWeek : number, offset : number) : number {
        dayOfWeek += offset;
        while (dayOfWeek < 0) {
            dayOfWeek += 7;
        }
        while (dayOfWeek > 6) {
            dayOfWeek -= 7;
        }
        return dayOfWeek;
    }

    public static DATE_TIME_FORMAT_FILES: DateFormatPattern = [
        {component: "year", style: "decimal", minLength: 4},
        {component: "month", style: "decimal", minLength: 2},
        {component: "date", style: "decimal", minLength: 2},
        "_",
        {component: "hour", style: "decimal", minLength: 2},
        {component: "minute", style: "decimal", minLength: 2},
        {component: "second", style: "decimal", minLength: 2},
    ];

    /** If you want to get a <a download=> with a file name having a date and time.
     *  The resulting format is yyyyMMdd_HHmmss.
     */
    public static getCurrentDateTimeForFilename(timeZone : string) : string {
        const currentUtc = DateHelper.getCurrentTimeSeconds();
		// not passing a locale is fine because the date format is locale independent.
        return DateHelper.formatUtcSecondsWithTimeZone(currentUtc, timeZone, DateHelper.DATE_TIME_FORMAT_FILES, undefined);
    }

    public static formatMostSignificantPartOfTimeDifference(difference: TimeDifference, locale: string): string {
        const globalize = Globalize(locale);
        if (difference.years != 0) {
            return globalize.relativeTimeFormatter("year")(difference.sign * difference.years);
        } else if (difference.months != 0) {
            return globalize.relativeTimeFormatter("month")(difference.sign * difference.months);
        } else if (difference.days >= 7) {
            return globalize.relativeTimeFormatter("week")(Math.trunc(difference.sign * difference.days / 7));
        } else {
            return globalize.relativeTimeFormatter("day")(difference.sign * difference.days);
        }
    }

    public static getTimeZone(): string {
        // https://stackoverflow.com/a/34602679
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    public static fromJsDate(date: Date, timeZone?: string): FancyDate {
        return DateHelper.fromUtcMillis(date.getTime(), timeZone);
    }

    public static fromUtcMillis(ts: number, timeZone?: string): FancyDate {
        return DateHelper.fromUtcSeconds(ts / 1000, timeZone);
    }

    public static fromUtcSeconds(utcSeconds: number, timeZone?: string): FancyDate {
        timeZone ??= DateHelper.getTimeZone();
        return new FancyDate({
            utcSeconds,
            timeZone,
        });
    }
}
