import * as Globalize from "globalize";
import Cldr from "clazzes-core/i18n/cldr.js";
import en from "clazzes-core/i18n/en";
import de from "clazzes-core/i18n/de";

Globalize.load(Cldr);
Globalize.loadMessages({
    root: en,
    de,
    en,
});

export { Globalize };
