/*
 * $Id: $
 *
 * Copyright (C) 2020 ITEG IT-Engineers GmbH
 */

import {TinyLog} from "clazzes-core/log/TinyLog";
import {IJobStatusService} from "clazzes-core/svc/IJobStatusService";
import {JobStatusDto} from "clazzes-core/dto/job/JobStatusDto";

const className = "org.clazzes.util.JobHelper";
const log = new TinyLog(className);

interface JobStatusQueryForDownloadParams {
    jobStatusService : IJobStatusService;
    jobId : string;
    queryInterval? : number; // ms
    downloadUrlGetter : (jobId : string) => string; // jobId => url
    doneCallback? : (status : JobStatusDto) => void;
//    postUrlOpenFunction : any;
}

export class JobHelper {

    // Hint: Function copied from cdes
    public static registerJobStatusQueryForDownload(params : JobStatusQueryForDownloadParams) : void {

        let jobStatusService : IJobStatusService = params.jobStatusService;
        let jobId : string = params.jobId;
        let queryInterval : number = params.queryInterval ?? 1000;
        let downloadUrlGetter : (jobId : string) => string = params.downloadUrlGetter;
        let doneCallback : (status : JobStatusDto) => void = params.doneCallback;
            
        jobStatusService.waitForJob(jobId, queryInterval).then((status : JobStatusDto) => {
            // jobId not known by jobStatusService
            if (status == null) {
                throw new Error("JobId [" + jobId + "] not known by JobStatusService.");
            }

            if (status.lastLogMessages != null) {
                for (let i = 0; i < status.lastLogMessages.length; i++) {
                    // TODO If needed, implement a replacement for former Topic.publish call
                    //Topic.publish("message/message", status.lastLogMessages[i]);
                }
            } 
                
            if (status.done) {
                if (doneCallback != null) {
                    doneCallback(status);
                }
                if (status.exceptionMessage) {
                        
                    if (log.isDebugEnabled()) {
                        log.debug("Job [" + jobId + "] failed :", status.exceptionMessage); 
                    }
                        
                    // TODO If needed, implement a replacement for former Topic.publish call
                    //Topic.publish("message/error", status.exceptionMessage);
                } else {
                    let url : string = downloadUrlGetter(jobId);
                    if (log.isDebugEnabled()) {
                        log.debug("Job [" + jobId + "] finished, opening result in new window.");     
                    }
                    window.open(url);

                    /*
                    if (status.result.disposition == "inline") {
                    } else {
                            if (log.isDebugEnabled()) {
                                log.debug("Print job [",jobId,"] finished, downloading result in iframe.");     
                            }
                            var downloadFrame = applicationContext.getFancyIFrame();
                            downloadFrame.set("src", url);
                        }

                        if (postUrlOpenFunction != null) {
                            postUrlOpenFunction();
                        }                                
                    */
                }
            } else {
                if (log.isDebugEnabled()) {
                    log.debug("Job [" + jobId + "] not finished yet, waiting another 1000ms...");       
                }
                JobHelper.registerJobStatusQueryForDownload(params);
            }

        }, (err : any) => {
            log.error("waitForJob failed", err);
        });                     
    }
}
