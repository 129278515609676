/*
 * $Id: $
 *
 * Copyright (C) 2022 ITEG IT-Engineers GmbH
 */

import { hash } from "./Hash";

// internal implementation details for HashMap and HashSet.
export function makeIterableIterator<T>(get: () => Iterator<T>): IterableIterator<T> {
    return Object.assign(get(), {
        [Symbol.iterator]: () => makeIterableIterator(get),
    });
}

// FROM: https://stackoverflow.com/questions/13955157/how-to-define-static-property-in-typescript-interface
export type StaticImplements<I extends new (...args: any[]) => any, C extends I> = InstanceType<I>;

export abstract class HashTable<K, E> {
    private underlying: Map<unknown, E[]> = new Map();

    protected constructor(
        private contentEquals: ((a: K, b: K) => boolean) | undefined | null,
        private contentHash: ((value: K) => unknown) | undefined | null,
    ) {
    }

    private _equals(a: K, b: K): boolean {
        if (this.contentEquals != null) {
            return this.contentEquals(a, b);
        } else if (typeof a !== typeof b) {
            return false;
        } else if (typeof a === "object" && a !== null && "equals" in a) {
            // @ts-ignore
            return a.equals(b);
        } else if (typeof b === "object" && b !== null && "equals" in b) {
            // @ts-ignore
            return b.equals(a);
        } else {
            return a === b;
        }
    }

    private _hash(a: K): unknown {
        if (this.contentHash != null) {
            return this.contentHash(a);
        } else {
            return hash(a);
        }
    }

    protected abstract _getKey(entry: E): K;

    protected _get(key: K): E | undefined {
        const values = this.underlying.get(this._hash(key));
        if (values == null || values.length === 0) {
            return undefined;
        } else {
            // Array iterators / for … of is banned because of allocations.
            for (let i = 0; i < values.length; i++) {
                const entry = values[i];
                const k = this._getKey(entry);
                if (this._equals(k, key)) {
                    return entry;
                }
            }
            return undefined;
        }
    }

    protected _set(entry: E): this {
        const key = this._getKey(entry);
        const hash = this._hash(key);
        const values = this.underlying.get(hash);
        if (values == null) {
            this.underlying.set(hash, [entry]);
        } else if (values.length === 0) {
            values.push(entry);
        } else {
            for (let i = 0; i < values.length; i++) {
                if (this._equals(this._getKey(values[i]), key)) {
                    values[i] = entry;
                    return this;
                }
            }
            values.push(entry);
        }
        return this;
    }

    public clear(): void {
        this.underlying.clear();
    }

    protected _delete(key: K): E | undefined {
        const hash = this._hash(key);
        const values = this.underlying.get(hash);
        if (values == null || values.length <= 0) {
            return undefined;
        } else if (values.length === 1) {
            if (this._equals(this._getKey(values[0]), key)) {
                this.underlying.delete(hash);
                return values[0];
            }
            return undefined;
        } else {
            let i = 0;
            for (let i = 0; i < values.length; i++) {
                const entry = values[i];
                const k = this._getKey(entry);
                if (this._equals(k, key)) {
                    values.splice(i, 1);
                    return entry;
                }
                i++;
            }
            return undefined;
        }
    }

    public has(key: K): boolean {
        const values = this.underlying.get(this._hash(key));
        if (values == null || values.length === 0) {
            return false;
        } else {
            for (let i = 0; i < values.length; i++) {
                const entry = values[i];
                const k = this._getKey(entry);
                if (this._equals(k, key)) {
                    return true;
                }
            }
            return false;
        }
    }

    protected *_entries(): Generator<E> {
        for (const values of this.underlying.values()) {
            for (let i = 0; i < values.length; i++) {
                const entry = values[i];
                yield entry;
            }
        }
    }

    public get size(): number {
        let size = 0;
        for (const values of this.underlying.values()) {
            size += values.length;
        }
        return size;
    }
}

export default HashTable;
