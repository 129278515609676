import africaZoneInfoFile       from "clazzes-core/zoneinfo/africa";
import antarcticaZoneInfoFile   from "clazzes-core/zoneinfo/antarctica";
import asiaZoneInfoFile         from "clazzes-core/zoneinfo/asia";
import australasiaZoneInfoFile  from "clazzes-core/zoneinfo/australasia";
import backwardZoneInfoFile     from "clazzes-core/zoneinfo/backward";
import etceteraZoneInfoFile     from "clazzes-core/zoneinfo/etcetera";
import europeZoneInfoFile       from "clazzes-core/zoneinfo/europe";
import northamericaZoneInfoFile from "clazzes-core/zoneinfo/northamerica";
//import pacificnewZoneInfoFile from "clazzes-core/zoneinfo/pacificnew";
import southamericaZoneInfoFile from "clazzes-core/zoneinfo/southamerica";
import zone1970_tabFile         from "clazzes-core/zoneinfo/zone1970.tab";

/**
 * This export const is a separation between the raw-loader and TimeZoneHelper,
 * so that tape/proxyfier can provide test data as the dependency.
 */
export const ZoneInfos = {
    africaZoneInfo : africaZoneInfoFile,
    antarcticaZoneInfo : antarcticaZoneInfoFile,
    asiaZoneInfo : asiaZoneInfoFile,
    australasiaZoneInfo : australasiaZoneInfoFile,
    backwardZoneInfo : backwardZoneInfoFile,
    etceteraZoneInfo : etceteraZoneInfoFile,
    europeZoneInfo : europeZoneInfoFile,
    northamericaZoneInfo : northamericaZoneInfoFile,
    //pacificnewZoneInfo : pacificnewZoneInfoFile,
    southamericaZoneInfo : southamericaZoneInfoFile,
    zone1970_tab : zone1970_tabFile,
};
