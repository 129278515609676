/*
 * $Id: $
 *
 * Copyright (C) 2022 ITEG IT-Engineers GmbH
 */

import HashTable, { makeIterableIterator, StaticImplements } from "./HashTable";

// A slightly enhanced version of the standard Set that can store complex keys.
// Hashing will be done one of three ways:
// 1. If contentHash is given it will be used for hashing.
// 2. If the value is an object and has a hashCode method that method will be used.
// 3. Otherwise the value will be directly used as a hash.
// Equality is similar:
// 1. If contentEquals is given it will be used for equality.
// 2. If the value is an object and has an equals method that method will be used.
// 3. Otherwise strict comparison will be used.
export class HashSet<T> extends HashTable<T, T> implements StaticImplements<SetConstructor, typeof HashSet> {
    static get [Symbol.species](): SetConstructor {
        return this;
    }

    public [Symbol.toStringTag]: string;

    constructor();
    constructor(it: Iterable<T>);
    constructor(contentEquals: (a: T, b: T) => boolean, contentHash: (value: T) => unknown);
    constructor(it: Iterable<T>, contentEquals: (a: T, b: T) => boolean, contentHash: (value: T) => unknown);

    constructor(a?: unknown, b?: unknown, c?: unknown) {
        if (arguments.length === 0) {
            super(undefined, undefined);
            return;
        } else if (arguments.length === 1) {
            super(undefined, undefined);
            for (const value of a as Iterable<T>) {
                this.add(value);
            }
        } else if (arguments.length === 2) {
            super(a as (a: T, b: T) => boolean, b as (value: T) => unknown);
        } else if (arguments.length === 3) {
            super(b as (a: T, b: T) => boolean, c as (value: T) => unknown);
            for (const value of a as Iterable<T>) {
                this.add(value);
            }
        }
    }

    protected _getKey(entry: T): T {
        return entry;
    }

    public add(key: T): this {
        return this._set(key);
    }

    public delete(key: T): boolean {
        return this._delete(key) !== undefined;
    }

    public [Symbol.iterator](): IterableIterator<T> {
        return makeIterableIterator(() => this._entries());
    }

    public entries(): IterableIterator<[T, T]> {
        return makeIterableIterator(function*(this: HashSet<T>) {
            for (const entry of this._entries()) {
                yield [entry, entry] as [T, T];
            }
        }.bind(this));
    }

    public keys(): IterableIterator<T> {
        return makeIterableIterator(() => this._entries());
    }

    public values(): IterableIterator<T> {
        return makeIterableIterator(() => this._entries());
    }

    public forEach(callback: (value: T, key: T, set: HashSet<T>) => void, thisArg?: unknown): void {
        for (const value of this._entries()) {
            if (arguments.length > 1) {
                callback.call(thisArg, value, value, this);
            } else {
                callback(value, value, this);
            }
        }
    }
}

Object.defineProperty(HashSet.prototype, Symbol.toStringTag, {
    configurable: true,
    value: "HashSet",
});

export default HashSet;
