export default {
    "januaryLong": "Januar",
    "februaryLong": "Februar",
    "marchLong": "März",
    "aprilLong": "April",
    "mayLong": "Mai",
    "juneLong": "Juni",
    "julyLong": "Juli",
    "augustLong": "August",
    "septemberLong": "September",
    "octoberLong": "Oktober",
    "novemberLong": "November",
    "decemberLong": "Dezember",

    "januaryShort": "Jan",
    "februaryShort": "Feb",
    "marchShort": "Mrz",
    "aprilShort": "Apr",
    "mayShort": "Mai",
    "juneShort": "Jun",
    "julyShort": "Jul",
    "augustShort": "Aug",
    "septemberShort": "Sep",
    "octoberShort": "Okt",
    "novemberShort": "Nov",
    "decemberShort": "Dez",

    "mondayLong": "Montag",
    "tuesdayLong": "Dienstag",
    "wednesdayLong": "Mittwoch",
    "thursdayLong": "Donnerstag",
    "fridayLong": "Freitag",
    "saturdayLong": "Samstag",
    "sundayLong": "Sonntag"
};